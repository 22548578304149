import React, { useRef, useState, useEffect } from "react";

import { useHistory } from "react-router-dom";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import "swiper/css/scrollbar";

import "./styles.css";

// import required modules
import { Scrollbar, Mousewheel, FreeMode, Thumbs } from "swiper";

import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css'; // This only needs to be imported once in your app

import { isMobile } from "react-device-detect";

export default function ProductImageScroller(props) {
    // console.log("ProductImageScroller", props);
    const [showThumbnail, setShowThumbnail] = useState(false);
    const [thumbsSwiper, setThumbsSwiper] = useState(null);

    const [imageIndex, setImageIndex] = useState(0);
    const [showImgLoader, setShowImgLoader] = useState(false);    
    const [showImgZoomBox, setShowImgZoomBox] = useState(false);

    const history = useHistory();

    const product = props.product;
    const variant = props.selectedProductVariant || {};
    const variantImages = variant.variantImages || [];
    if (variantImages.length === 0)
        variantImages.push({ fullPath: product.thumbnail_sm_img_full_path });
            
    const ImgDiv = (imagPath) => {
        return <img src={imagPath} alt="Hintage" loading="lazy"/>
    }
    
    const handleMouseEnter = () => {
        setShowThumbnail(true);
    };

    const handleMouseLeave = () => {
        setShowThumbnail(false);
    };

    const showImgGallery = () => {
        setShowImgLoader(true);
        setShowImgZoomBox(true);
    }  

    return <>
          {variantImages.length > 0 &&
            <>
                <div className="top-0 left-0" style={{marginTop: isMobile ? "-45px" : "0px"}}>
                    <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} className="cursor-pointer"
                        style={{
                            display: "flex", height: isMobile ? `${innerHeight - 200}px` : "100vh", backgroundColor: "#FFF",
                            //  height: isMobile ? "52vh" : "80vh",                              
                             marginTop: "0px"
                             }}>
                        <div className="t-0">
                            <Swiper
                                direction={"vertical"}
                                slidesPerView={1}
                                spaceBetween={isMobile ? 0 : 40}
                                thumbs={{ swiper: thumbsSwiper }}
                                modules={[FreeMode, Mousewheel, Scrollbar, Thumbs]}
                                scrollbar={{ hide: false }}
                                mousewheel={true}
                                className="mySwiper2"
                                onClick={() => showImgGallery()}
                            >
                                {variantImages.map ((item, key) => <SwiperSlide key={`m${key}`}><div className={`h-full ${isMobile ? "p-0 pr-3" : "pr-5"}`}>{ImgDiv(item.fullPath)}</div></SwiperSlide>)}
                            </Swiper>
                        </div>
                        {!isMobile && 
                        <div style={{flex: "15%"}}>
                            <Swiper
                                direction={"vertical"}
                                onSwiper={setThumbsSwiper}
                                spaceBetween={0}
                                slidesPerView={7}
                                freeMode={true}
                                mousewheel={true}
                                watchSlidesProgress={true}
                                modules={[FreeMode, Mousewheel, Thumbs]}
                                className="mySwiper"
                            >
                                {variantImages.map ((item, key) => 
                                <SwiperSlide key={`sm${key}`}>
                                    {showThumbnail && <div className="m-0 p-0 h-fit">{ImgDiv(item.fullPath)}</div>}
                                </SwiperSlide>)}
                        </Swiper>
                        </div>}
                    </div>
            </div>
            {showImgLoader &&
                <div
                className="absolute top-1/2 left-1/2 inline-block h-8 w-8 animate-spin rounded-full border-amber-400 border-4 border-solid border-current border-r-transparent align-[-0.125em] text-warning motion-reduce:animate-[spin_1.5s_linear_infinite]"
                style={{zIndex: "999"}} role="status">
                <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"
                  >Loading...</span>
              </div>
            }
            {showImgZoomBox &&
                <Lightbox
                    mainSrc={variantImages[imageIndex].fullPath}
                    nextSrc={variantImages[(imageIndex + 1) % variantImages.length].fullPath}
                    prevSrc={variantImages[(imageIndex + variantImages.length - 1) % variantImages.length].fullPath}
                    onCloseRequest={() => {
                        setShowImgLoader(false);
                        setShowImgZoomBox(false);
                    }}
                    onMovePrevRequest={() => 
                        setImageIndex((imageIndex + variantImages.length - 1) % variantImages.length) 
                    }
                    onMoveNextRequest={() =>
                        setImageIndex((imageIndex + 1) % variantImages.length)
                    }
                    // discourageDownloads={true}
                    animationDuration={1000}
                    imageTitle={variant.variant_title}
                    clickOutsideToClose={true}
                    keyRepeatLimit={300}
                />
            }
        </>
        }
    </>
}
