import React, { useState, useEffect } from "react";
import Banner from "../banner";
import { Helmet } from "react-helmet-async";
import CategoryNavigation from "../category-navigation";

import NavBar from "../navbar";

export default function Home(props) {
    const [show, setShow] = useState(false);
   
    return <>
        <Helmet>
            <title>Hintage - A Heritage from Hind</title>
            <meta
            name="description"
            content="Hintage - A Heritage from Hind"
            />
        </Helmet>
        <NavBar headerClassName="text-white" />        
        <Banner sideBarVisible={show} />
        <CategoryNavigation />
    </>
    
}