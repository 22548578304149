import React, { useRef, useEffect } from "react";


import { useHistory } from "react-router-dom";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import "./styles.css";

// import required modules
import { Autoplay, Mousewheel, Pagination, Navigation } from "swiper";

import { isMobile } from "react-device-detect";

export default function ProductItem(props) {
    const swiperRefLocal = useRef();
    const history = useHistory();

    // console.log("ProductItem", props);

    useEffect(() => {
        swiperRefLocal.current.swiper.autoplay.pause();
      }, []);

      const pagination = {
        clickable: true,
        renderBullet: function (index, className) {
          return  '<span class="' + className + '">' + (index + 1) + "</span>";
        },
      };
      
    const ImgDiv = (imagPath) => {
        return <img src={imagPath} alt="Hintage" loading="lazy" />
        // return <img src='https://dyson-h.assetsadobe2.com/is/image/content/dam/dyson/leap-petite-global/dynamic-media/personal-care/308c/primary/395906-01-wa.png?scl=1&fmt=png-alpha' alt="Hintage" loading="lazy" />
    }

    const handleMouseEnter = () => {
        // console.log("mouseOver", swiperRefLocal.current.swiper);
        swiperRefLocal.current.swiper.autoplay.resume();
    };

    const handleMouseLeave = () => {
        // console.log("mouseLeave", swiperRefLocal.current.swiper);
        swiperRefLocal.current.swiper.autoplay.pause();
    };

    const variantImages = props.variantImages || [];
    const variant = props.variant || {}; 

    return <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} className="cursor-pointer">
        {variantImages.length > 0 && 
            <Swiper
                ref={swiperRefLocal}
                direction={"horizontal"}
                slidesPerView={1}
                spaceBetween={0}
                // mousewheel={true}
                // keyboard={{ enabled: true }}
                modules={[Mousewheel, Autoplay, Pagination, Navigation]}
                className="swiper"
                pagination={pagination}
                // pagination={{ type: "fraction" }}
                autoplay={{
                    delay: 2500,
                    disableOnInteraction: true
                }}
                onClick={() => history.push(`/product-variant?pkey=${variant.product_key}&vkey=${variant.variant_key}`)}
            >
                {variantImages.map ((item, key) => <SwiperSlide key={key}>{ImgDiv(item.fullPath)}</SwiperSlide>)}
            </Swiper>
        }
    </div>;
}
