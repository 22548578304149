import React, { useRef, useEffect, useState } from "react";
import {
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    Typography,
    Button,
    IconButton
  } from "@material-tailwind/react";
import ncNanoId from "../../../../../../utilities/ncNanoId";
import InfiniteScroll from "react-infinite-scroll-component";

// react-router-dom components
import { useHistory } from "react-router-dom";

import ProductItem from "../item";
import { isMobile } from "react-device-detect";
import ReadMore from "../../../read-more";
import ProductLoader from "../../product-loader";
import CText from "../../../../../../utilities/typography/ctext";
import SText from "../../../../../../utilities/typography/stext";

export default function ProductGrid(props) {
    const history = useHistory();

    const { productList }  = props;
    const gridCol = props.gridCol || 1;
    const fixItems = props.fixItems || false;

    const autoFetch = (props.autoFetch || true);
    const autoFetchApi = props.autoFetchApi;
    const hasMore = props.hasMore;

    const topMargin = isMobile ? "mt-4 max-w-sm p-0" : "mt-8 max-w-screen-sm";    
    
    const getAllVariantImages = (product, itemCountPerRow) => {
        const variantList = product.variantList || [];
        const variantImagesComponent = [];

        variantList.map((variant, key) => {
            const variantImages = variant.variantImages || [];
            if (variantImages.length === 0)
            variantImages.push({ fullPath: product.thumbnail_sm_img_full_path });

            const flexCol = itemCountPerRow > 1;
            variantImagesComponent.push(
                <div key={ncNanoId()} className={`${topMargin} flex grow items-center justify-start place-content-center`}>
                    <div className="relative grow">
                        <button className="absolute z-20 w-8 h-8 rounded-full bg-opacity-60 bg-white hover:bg-zinc-300 text-zinc-900 hover:text-black right-5 top-5">
                            <span className="material-symbols-outlined text-sm " onClick={() => {}} >add</span>
                        </button>
                        <div className="grid grid-cols-1 grid-rows-1 p-1" >
                            <ProductItem variant={variant} variantImages={variantImages}/>
                            {flexCol ? 
                            <div className={`flex flex-col`}>
                                <CText className="text-gray-500 font-bold whitespace-nowrap p-2 text-right">
                                    <span className="material-symbols-outlined text-xs" onClick={() => {}} >currency_rupee</span>
                                    {variant.sell_price}
                                </CText>
                                <CText className="p-2">
                                    {!isMobile && itemCountPerRow === 1 ? product.product_title : <ReadMore charLimit={90} text={product.product_title} color="white" />}
                                </CText>
                            </div> :
                            <div className={`flex flex-row h-24`}>
                                <CText className="p-2">
                                    {!isMobile && itemCountPerRow === 1 ? product.product_title : <ReadMore charLimit={90} text={product.product_title} color="white" />}
                                </CText>
                                <CText className="text-gray-500 font-bold whitespace-nowrap p-2 text-right">
                                    <span className="material-symbols-outlined text-xs" onClick={() => {}} >currency_rupee</span>
                                    {variant.sell_price}
                                </CText>
                            </div>
                            }
                        </div>
                    </div>
                </div>
                );              
        })
        return variantImagesComponent;
    }

    const GetVariantColumnItems = (productList, startIdx, endIdx) => {
        const gridItems = [];
        for (let i =startIdx; i < Math.min(productList.length, endIdx); i++) {
            const product = productList[i];
            const item = getAllVariantImages(product, endIdx - startIdx);
            gridItems.push(<div className={`flex flex-grow items-center justify-center gap-0 mt-0`}>{item}</div>);
        }
        return gridItems;
    }
    
    const productVariantCardList = [];
    for (let i=0; i < productList.length; i++) { 
        let gridColCount = gridCol > 1 ? gridCol : Math.floor(Math.random() * (isMobile ? 3 : 4));
        gridColCount = (gridColCount > productList.length - i) ? productList.length - i : gridColCount;
        const productVariantComponent = GetVariantColumnItems(productList, i, i + gridColCount);

        const gridColumnStyle = gridColCount == 1 ? "" : `grid-cols-${gridColCount}`
        productVariantCardList.push(<div className={`flex items-start justify-center ${gridColumnStyle} mt-0 max-h-full w-full`}>
            {productVariantComponent}
        </div>);
        i += gridColCount-1;
    }

    return <div className="mt-0 max-w-full "> 
        {fixItems ? productVariantCardList :
            <InfiniteScroll dataLength={productList.length} 
                next={() => autoFetchApi && autoFetchApi() }
                hasMore={hasMore}
                loader={hasMore && <ProductLoader><CText>Loading products . . .</CText></ProductLoader>}
                endMessage={<div className=" text-center mt-30"><SText>- No more products - </SText></div>}
                className=" border-collapse border-0 p-0 "
            >
                {productVariantCardList}
            </InfiniteScroll>
        }
        </div>
}
