import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Footer from "../footer";
import NetworkError from "../network-error";
import { isMobile } from "react-device-detect";
import NavBar from "../navbar";

export default function Wrapper(props) {
    const [show, setShow] = useState(false);
    const {route, children } = props;
    const [showDelayFooter, setShowDelayFooter] = useState(false);
   
    const errorStore = useSelector((state) => state.error);
    // console.log("Wrapper errorStore", errorStore, props);
    const apiError = errorStore.apiError;
    
    const showFooter = route.footer && route.footer == true;
    const showMobileFooter = route.footerMobile && route.footerMobile == true;

    useEffect(() => {
        setTimeout(() => setShowDelayFooter(true), 2500);
    }, []);
      
    return <div>
        {apiError && <NetworkError />}
        <NavBar onSideBarVisible={() => setShow(show)} />
        {children}
        {showDelayFooter && <>
            {isMobile && (!showMobileFooter || showMobileFooter === true) ? <Footer width="99vw"/> : <></>}
            {!isMobile && (!showFooter || showFooter === true) ? <Footer width="99vw"/> : <></>}
        </>}
    </div>
}