import CommonAction from "../Common/CommonAction";
import ActionUtility from "../../utilities/ActionUtility";
import CustomerEffect from "./CustomerEffect";
import Headers from "../utils/http/Headers";

export default class CustomerAction {
  static SAVE_USER_INQUIRY = "CustomerAction.SAVE_USER_INQUIRY";
    
  static saveProductInquiry = (inquiryData) => {
    return async (dispatch, getState) => {
      const result = await CustomerEffect.saveProductInquiry(inquiryData);
      if (result.status == "success") {
        //Store User Info to tracking, not response
        dispatch(ActionUtility.createAction(CustomerAction.SAVE_USER_INQUIRY, inquiryData));
      }
      return result;
    };
  }
}
