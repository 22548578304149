import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { Scrollbar } from "swiper";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import "./styles.css";

// import required modules
import { Keyboard, Autoplay, Mousewheel, Pagination, Navigation } from "swiper";

import { useSelector } from "react-redux";  //Reading Data
import Footer from "../footer";
import SText from "../../../../utilities/typography/stext";

export default function Banner() {
    // console.log("Banner");

    const productStore = useSelector((state) => state.products);
    // console.log("productsStore", productStore);
    // const categoryList = productStore.productCategoryList.data;
    const selectedCategoryItem = productStore.selectedCategoryItem;

    // console.log("selectedCategoryItem", selectedCategoryItem);
    
    const ImgDiv = (imagPath) => {
        return <a href={`/#/product-listing?ckey=${selectedCategoryItem.category_key}&sckey=*`} className="w-full h-full">
            <img class="media-image__image" alt="" src={imagPath}></img>
        </a>
        // return <a href={`/#/product-listing?ckey=${selectedCategoryItem.category_key}&sckey=*`}>
        //         <div style={{
        //             // cursor: 'pointer',
        //             height: "100vh", margin: 0, padding: 0, maxWidth: "100vw", overflow: 'auto', width: '100vw',
        //             backgroundImage: `url(${imagPath})`,
        //             backgroundSize: "cover",
        //             backgroundPosition: "top",
        //             minHeight: "100%",
        //             height: "100vh",
        //             position: "relative",
        //             backgroundRepeat: "no-repeat, no-repeat",
        //             // backgroundPosition: "right, left"
        //         }} />
        //     </a>
    }

    return <>
            {selectedCategoryItem.category_key !== "*" &&
                <div style={{zIndex: 3, position: 'absolute', top: '3%', width: '100%', textAlign: 'center' }}>
                    <SText className={"uppercase text-white"}>{selectedCategoryItem.category_name}</SText>
                </div>}
            {selectedCategoryItem && selectedCategoryItem.banner_images && selectedCategoryItem.banner_images.length > 0 && 
                <Swiper
                    direction={"vertical"}
                    slidesPerView={1}
                    spaceBetween={0}
                    mousewheel={true}
                    rewind={true}
                    scrollbar={{
                        hide: true,
                    }}
                    keyboard={{
                        enabled: true,
                    }}
                    autoplay={{
                        delay: 5000,
                        disableOnInteraction: true,
                    }}
                    // pagination={{
                    //     clickable: true,
                    // }}
                    // navigation={true}
                    modules={[Keyboard, Autoplay, Mousewheel, Pagination, Navigation]}
                    className="mySwiper"
                >
                    {selectedCategoryItem.banner_images.map ((item, key) => <SwiperSlide key={key}>{ImgDiv(item.fullPath)}</SwiperSlide>)}
                    <SwiperSlide key={"footer_key"}><Footer /></SwiperSlide>                    
                </Swiper>
            }
        </>;
}
