import axios from "axios";
import Headers from "./Headers";

// import HttpErrorResponseModel from '../model/HttpErrorResponseModel';
// import Helper from '../Utilities/Helper';
// import SecureData from '../Utilities/SecureData';

axios.interceptors.request.use(
  (config) => {
    const token = Headers.getSessionToken();
    console.log('TOKEN PRINT >> ', token)
    // let isEncryptData = SecureData.isEncryptionApis(config.url);
    if (token) {
      let headers = Headers.getCommonHeadersWithToken();
      console.log("REQUEST HEADERS >> ", headers);
      config.headers = {
        ...headers,
        ...config.headers,
      };
    } else {
      config.headers = { ...Headers.getCommonHeaderParams(), ...config.headers };
    }
    // config.timeout = 3600000;
    config.timeout = 45000;
    config.validateStatus = (status) =>
      (status >= 200 && status < 300) || (status >= 400 && status < 500);

    console.log("REQUEST >> ", config);
    return config;
  },
  (error) => {
    console.log("REQ ERROR >> ", error);

    return errorHandle(error);
  }
);
axios.interceptors.response.use(
  async (response) => {
    console.log("RESPONSE >> ", response);
    // if (typeof response.data === 'string') {
    //   let finalResData = SecureData.decryption(response.data);
    //   response.data = JSON.parse(finalResData);
    // }
    console.log("RESPONSE >> ", response.data);
    if (response.status < 200 || response.status > 207) {
      let message = "Something went worng";
      try {
        switch (response.status) {
          case 401:
            try {
              // TODO: Interceptor >> Redirect to login screen
              message = "Due to inactivity we have logged you out,\nplease log in to continue.";
              console.log("Token is expired. Please login again");
              global.tokenExpired();
              // return response.data;
            } catch (error) {
              console.log("ERROR 401 >> ", error);
            }
            break;
          default:
            // TODO: Interceptor >> Handle other api response status
            console.log("Somethig went wrong");
            break;
        }
      } catch (error) {
        console.log("ERROR RESPONSE >> ", error);
      }
      const { status, data, request } = response;
      if (data.success === false) {
        return _fillInErrorWithDefaults(
          {
            status,
            message: data.errors.join(" - "),
            errors: data.errors,
            url: request ? request.responseURL : response.config.url,
            raw: response.data,
          },
          response.config
        );
      }
      // Helper.ShowToast(
      //   'Res: Handle: Error ' + message ? message.toString() : 'not found',
      // );
      return response.data;
    }
    return response.data;
  },
  async (error) => {
    return errorHandle(error, error.response);
  }
);

function errorHandle(error, restRequest = {}) {
  console.log("API ERROR >> ", error, " >> REQUEST OBJECT >> ", restRequest);
  try {
    if (error.response) {
      // The request was made and the server responded with a status code that falls out of the range of 2xx
      const { status, statusText, data } = error.response;
      const errors =
        data && data.hasOwnProperty("errors") ? [statusText, ...data.errors] : [statusText];

      return _fillInErrorWithDefaults(
        {
          status,
          message: errors.filter(Boolean).join(" - "),
          errors,
          url: error.request.responseURL,
          raw: error.response,
        },
        restRequest
      );
    } else if (error.request) {
      // The request was made but no response was received `error.request` is an instance of XMLHttpRequest in the browser and an instance of http.ClientRequest in node.js
      const { status, statusText, responseURL } = error.request;

      return _fillInErrorWithDefaults(
        {
          status,
          message: statusText,
          errors: [statusText],
          url: responseURL,
          raw: error.request,
        },
        restRequest
      );
    }

    // Something happened in setting up the request that triggered an Error
    return _fillInErrorWithDefaults(
      {
        status: 0,
        message: error.message,
        errors: [error.message],
        url: restRequest.url,
        raw: error,
      },
      restRequest
    );
  } catch (error) {
    console.log("ERROR ERROR >> ", error);
  }
}

function _fillInErrorWithDefaults(error, request) {
  const model = {};

  model.status = error.status || 0;
  model.message = error.message || "Error requesting data";
  model.errors = error.errors.length ? error.errors : ["Error requesting data"];
  model.url = error.url || request.url;
  model.raw = error.raw;

  // Remove anything with undefined or empty strings.
  model.errors = model.errors.filter(Boolean);

  return model;
}

export default axios;
