import React, { useRef, useState, useEffect } from "react";

import queryString from 'query-string';

// react-router-dom components
import { useHistory } from "react-router-dom";

import { useDispatch } from "react-redux";  //Requesting and Storing Data
import { useSelector } from "react-redux";  //Reading Data

// import { enqueueSnackbar } from "notistack";

import ProductGrid from "./product-grid/grid";
import FilterBar from "../navbar/filterbar";
import NavBar from "../navbar";
import ErrorAction from "../../../../store/Error/ErrorAction";
import ProductMenu from "./product-menu";
import { Dialog, DialogBody, DialogFooter, DialogHeader } from "@material-tailwind/react";
import Button from "../form-components/button";
import ProductsEffect from "../../../../store/Products/ProductsEffect";
import PageContentLoader from "./content-loader";
import YouMayLike from "../product-variant/you-may-like/index,";
import CText from "../../../../utilities/typography/ctext";
import SText from "../../../../utilities/typography/stext";

export default function ProductListing() {
  console.log("ProductListing");
  const dispatch = useDispatch();
  const history = useHistory();
  
  // Get Category List
  const productStore = useSelector((state) => state.products);
  // console.log("ProductListing -> productsStore", productStore);
  const categoryList = productStore.productCategoryList.data || [];

  const [filter, setFilter] = useState({});
  const [hasMore, setHasMore] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [gridCol, setGridCol] = useState(1);  //1, 4, 8
  const [paginationAttributes, setPaginationAttributes] = useState({ pageNo : 1, pageSize : 15, orderBy: 'ASC' });
  
  const [showNoProductMessage, setShowNoProductMessage] = useState(false);
  const [productList, setProductList] = useState([]);

  // Get Query String Items      
  let params = queryString.parse(window.location.hash.substring(window.location.hash.indexOf('?') + 1));
  // console.log("params", params, window.location.hash);
  const categoryKey = (!params.ckey || params.ckey === null) ? '*' : params.ckey;
  const subCategoryKey = (!params.sckey || params.sckey === null) ? '*' : params.sckey;
  
  
  useEffect(() => {
    initPage(window.location, window.location.hash);
    const unlisten = history.listen ((location) => {
      window.scrollTo(0, 0);
      setShowNoProductMessage(false);
      // console.log("Location Changed ---> : ", location.pathname, location);
      initPage(location, location.search);
    });
    return unlisten;
  }, [history]);

  function initPage (location, searchString) {
    //URL Check
    if (location.toString().indexOf("hintage.in") > -1 && location.toString().indexOf("localhost:3000") > -1) {
      dispatch(ErrorAction.showApiError(true));
    }
    else {
      // Get Query String Items      
      let params = queryString.parse(searchString.substring(searchString.indexOf('?') + 1));
      // console.log("params", params, searchString);
      const categoryKey = (!params.ckey || params.ckey === null) ? '*' : params.ckey;
      const subCategoryKey = (!params.sckey || params.sckey === null) ? '*' : params.sckey;
      
      // console.log("categoryKey, subCategoryKey", categoryKey, subCategoryKey);
      getAllProductCategories(categoryKey, [subCategoryKey], paginationAttributes, []);
    }
  }

  const getAllProductCategories = async (category_key, sub_category_key_list, pageAttributes, pList) => {
    setIsLoading(true);

    console.log ("getAllProductCategories pList", pList);
    const sub_filter_items = [];
    let foundAllSubCat = false;
    sub_category_key_list.map(c => {
      if (c === '*') foundAllSubCat = true;
      const cKey = c === '*' ? null : c;
      if (cKey !== null) sub_filter_items.push(cKey);
    });
    
    // setShowNoProductMessage(false);
    const result = await ProductsEffect.productList({category_key, sub_filter_items, ...pageAttributes});
    if (result.status === 0 && result.message === "Error requesting data") { //Network Error
      dispatch(ErrorAction.showApiError(true));
    }

    if (result.status === "success" && result.data && result.data.length > 0) {
      setShowNoProductMessage(false);
      setProductList(pList.concat(result.data));
      setHasMore(result.data.length === paginationAttributes.pageSize);
    }
    else {
      if (paginationAttributes.pageNo === 1)  //Show only when first attempt does not have products
      {
        setShowNoProductMessage(true);
        setProductList([]);
      }
      setHasMore(false);
      // setTimeout(() => setShowNoProductMessage(false), 10000);
      // if (category_key !== '*') {
      //   // enqueueSnackbar('Oops ! No products found in this category, please have a look at other options.', { variant: 'default' });
      //   if (foundAllSubCat)
      //     history.push(`/product-listing?ckey=${category_key}&sckey=*`);  //Show all Category
      //   else  
      //     history.push(`/product-listing?ckey=*&sckey=*`);  //Show all Category
      // }
    }
    setIsLoading(false);
  }

  async function autoFetchApi() {
    const pageAttributes = JSON.parse(JSON.stringify(paginationAttributes));
    pageAttributes.pageNo = pageAttributes.pageNo + 1;
    // console.log ("API Call Started - ", paginationAttributes);

    setPaginationAttributes ({...pageAttributes});
    return getAllProductCategories(categoryKey, [subCategoryKey], pageAttributes, productList);
  }


  async function onApplyFilter (filter) {
    console.log ("onApplyFilter = ", filter);
    if (filter.categoriesSelected) {
      const selectedCategories = filter.categoriesSelected.map(c => c.category_key);
      if (selectedCategories && selectedCategories.length > 0) {
        console.log ("onApplyFilter = selectedCategories = ", selectedCategories);
        await getAllProductCategories(categoryKey, selectedCategories, paginationAttributes, []);
        setFilter(filter);
      }
    }
  }


  // console.log ("ProductList -----> ", productList, categoryKey, subCategoryKey);
  const matchCat = categoryList.filter(c => c.category_key == categoryKey)
  const currentCategoryName = matchCat && matchCat.length > 0 ? matchCat[0].category_name : '';

  return <div className=" mt-40">
    <NavBar>
      <FilterBar menuTextColorClass={"text-black"} 
        onLayoutChange={() => setGridCol(gridCol === 1 ? 4 : (gridCol === 4 ? 6 : 1))} 
        categoryKey={categoryKey} subCategoryKey={subCategoryKey}
        onApplyFilter={onApplyFilter}
      />
      <ProductMenu categoryList={categoryList} categoryKey={categoryKey}/>
    </NavBar>
    
    {showNoProductMessage &&
      <div className=" absolute items-center justify-center w-full">
        <div  className="block ml-auto mr-auto shadow-md w-2/3 bg-zinc-50 mt-10">
        <SText className="font-semibold">
          <span className="material-symbols-outlined text-4xl p-2">info</span>
          <span className="ml-5">At the moment we do not have products available in this category !</span>
          <br /> <br />
        </SText>
        <CText className="px-5 pb-5">
          We are taking continuous efforts to bring you awesome products from various cultures of Hind! Meanwhile, please nontinue to exlore other awesome products we have showcased.
        </CText>
        </div>
      </div>
      // <Dialog
      //   open={showNoProductMessage}
      //   size="lg"
      //   handler={() => setShowNoProductMessage(false)}
      //   animate={{
      //     mount: { scale: 1, y: 0 },
      //     unmount: { scale: 0.9, y: -100 },
      //   }}
      // >
      //   <DialogHeader><SText className="font-semibold">No Products</SText></DialogHeader>
      //   <DialogBody divider>
      //     <SText className="text-gray-600">
      //       <span class="material-symbols-outlined">sentiment_dissatisfied</span>
      //       <b> Oops!</b> 
      //       Currently we don't have any products in this category !<br /> <br />
      //     </SText>
      //     <CText className="text-gray-600">
            
      //       We are taking continuous efforts to bring you some awesome products from various cultures of Hind. Meanwhile, please nontinue to exlore other awesome products we have showcased.<br /><br />
      //     </CText>
      //   </DialogBody>
      //   <DialogFooter>
      //     <Button onClick={() => setShowNoProductMessage(false)} className="mr-1 bg-white hover:bg-black" ><span>Close</span></Button>
      //   </DialogFooter>
      // </Dialog>
    }
    {isLoading ? <PageContentLoader /> :
      <>
        <SText className="uppercase font-semibold border-b-4 ml-7 mt-36 w-fit">{currentCategoryName}</SText>
          <ProductGrid productList={productList} categoryKey={categoryKey} subCategoryKey={subCategoryKey} gridCol={gridCol} 
            autoFetchApi={autoFetchApi} hasMore={hasMore} />
      </>
    }
    <>
      {<YouMayLike filter={filter} categoryKey={categoryKey} subCategoryKey={subCategoryKey} />}
    </>
  </div>
}
