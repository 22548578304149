import React, { useState } from "react";

// react-router-dom components
import { Link, useHistory } from "react-router-dom";

import { Helmet } from "react-helmet-async";
import InquiryForm from "./InquiryForm";

export default function ContactUs() {
    const history = useHistory();

    return (
      <div>
            <Helmet>
              <title>Hintage Contact - A Heritage from Hind</title>
              <meta
                name="description"
                content="Hintage Contact - A Heritage from Hind"
              />
            </Helmet>
            <div id="content" className="site-content">
                <div className="page-header dtable text-center header-transparent page-header-other">
                    <div className="dcell">
                        <div className="container">
                            <h1 className="page-title">Contact</h1>
                            <ul id="breadcrumbs" className="breadcrumbs none-style">
                                <li><a href="index.html">Home</a></li>
                                <li className="active">Contacts</li>
                            </ul>    
                        </div>
                    </div>
                </div>
            </div>

            <section className="contact-page">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 align-self-center mb-5 mb-lg-0">
                            <div className="contact-left">
                                <h2>Get in Touch</h2>
                                <p className="font14">Your email address will not be published. Required fields are marked *</p>
                                <InquiryForm />
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="contact-right">
                                <div className="ot-heading">
                                    <span>[ our contact details ]</span>
                                    <h2 className="main-heading">Build your Heritage</h2>
                                </div>
                                <p>Get in touch with us anytime, we endeavour to answer all enquiries within 24 hours on business days. We will be happy to answer your questions.</p>
                                <div className="contact-info">
                                    <i className="ot-flaticon-place"></i>
                                    <div className="info-text">
                                        <h6>OUR ADDRESS:</h6>
                                        <p>Mulund-West, India</p>
                                    </div>
                                </div>
                                <div className="contact-info">
                                    <i className="ot-flaticon-mail"></i>
                                    <div className="info-text">
                                        <h6>OUR MAILBOX:</h6>
                                        <p><a href="mailto:care@hintage.in">care@hintage.in</a></p>
                                    </div>
                                </div>
                                <div className="contact-info">
                                    <i className="ot-flaticon-phone-call"></i>
                                    <div className="info-text">
                                        <h6>OUR PHONE:</h6>
                                        <p><a href="tel:+91 99999 99999">+91 98929 83643</a></p>
                                    </div>
                                </div>
                                <div className="list-social">
                                    <ul>
                                        <li><a href="https://www.facebook.com/hintage" target="_blank"><i className="fab fa-facebook-f"></i></a></li>
                                        <li><a href="https://www.twitter.com/hintage_1" target="_blank"><i className="fab fa-twitter"></i></a></li>
                                        <li><a href="https://www.linkedin.com/in/hintage" target="_blank"><i className="fab fa-linkedin-in"></i></a></li>
                                        <li><a href="https://www.instagram.com/HintageIndia" target="_blank"><i className="fab fa-instagram"></i></a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
    </div>
  );
}