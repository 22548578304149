import React, { useState } from "react";

import { isMobile } from "react-device-detect";
import { Helmet } from "react-helmet-async";
import SText from "../../../../../utilities/typography/stext";
import HText from "../../../../../utilities/typography/htext";
import CText from "../../../../../utilities/typography/ctext";
import NText from "../../../../../utilities/typography/ntext";

function About() {

  return (
    <div>
        <Helmet>
            <title>About Hintage - A Heritage from Hind</title>
            <meta
            name="description"
            content="About Hintage - A Heritage from Hind"
            />
        </Helmet>
        <div className="container text-zinc-500 p-6 mt-32">
            <div className="row">
                <HText className="mb-12 text-center ">About Us</HText>
                <div className="text-justify">
                        <SText className="uppercase">Hintage: Embracing Heritage with a Modern Touch</SText>
                        <NText className="pt-2 pb-6">
                            Welcome to Hintage, an innovative brand driven by a passion for heritage, where we seamlessly blend the charm of yesteryear with contemporary design sensibilities. We offer a diverse range of vintage products for both home decor and commercial establishments, carefully curated for those who appreciate ancient art and culture.
                        </NText>
                        <SText className="uppercase">Our Unique Offerings</SText>
                        <NText className="pt-2 pb-6">
                            At Hintage, our extensive collection includes vintage home and commercial furniture, hand-painted pieces, and brass, bronze, and copper artifacts and utensils. Each product is meticulously handcrafted by skilled artisans, boasting an exclusive finish and exquisite artwork that sets Hintage apart in the market.<br />                                
                        </NText>
                        <SText className="uppercase">Our Vision</SText>
                        <NText className="pt-2 pb-6">
                            Our deep-seated vision revolves around reviving and disseminating ancient art, culture, and architecture. We strive to preserve age-old traditions while bringing them to the forefront of modern design, striking a balance between past and present. By collaborating with talented artisans, we showcase their work and foster a global appreciation for intricate art forms.<br />
                        </NText>
                        <SText className="uppercase">Our Commitment</SText>
                        <NText className="pt-2 pb-6">
                            Hintage's unwavering dedication to quality and authenticity is rooted in our commitment to cultural preservation, making us a leading force in the world of vintage design.<br />
                        </NText>
                        <SText className="uppercase">Empowering Artisans</SText>
                        <NText className="pt-2 pb-6">
                            We believe in empowering artisans and promoting their art worldwide through innovative use of technology. By leveraging digital platforms and technological advancements, we ensure that our products reach a global audience, providing artisans with the recognition and financial support they deserve.
                        </NText>
                        <SText className="uppercase">Our Impact</SText>
                        <NText className="pt-2 pb-6">
                            Our unique business model enables us to make a meaningful impact on the lives of artisans while offering customers an unparalleled shopping experience. With Hintage, you can be assured that you're not only investing in beautiful vintage pieces, but also supporting a brand that stands for cultural preservation, empowerment, and authenticity.<br /><br />
                        </NText>
                        <NText className="pt-2 pb-6">
                            Join us in celebrating the rich history and culture embedded in each handcrafted product, and become a part of the Hintage family today<br />
                        </NText>
                </div>
            </div>
        </div>
    </div>
  );
}

export default About;
