import React from "react";
import "./styles.css";

const ProductLoader = (props) => {
  return (<>
        {props.children}
        <div className="product-loader">
            <div></div>
            <div></div>
            <div></div>
        </div>
    </>);
};

export default ProductLoader;