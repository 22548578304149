import AuthAction from "./AuthAction";

const initState = {
  isLogin: false,
  userDetails: {
    first_name: "Guest",
    last_name: ""
  },
};
export default class AuthReducer {
  static initialState = initState;

  static reducer(state = AuthReducer.initialState, action) {
    if (action.error) {
      return state;
    }
    switch (action.type) {
      case AuthAction.SAVE_USER_DATA:
        return {
          ...state,
          userDetails: {
            ...state.userDetails,
          },
        };
      case AuthAction.LOGIN:
        let data = action.payload;
        if (data) {
          return {
            ...state,
            isLogin: true,
            userDetails: {
              ...state.userDetails,
              first_name: data.first_name,
              last_name: data.last_name
            },
          }
        }
        else {
          return {
            ...state,
            isLogin: false,
            userDetails: {
              first_name: "Guest",
              last_name: ""
            },
          };
        }
      case AuthAction.LOGOUT:
        return {
          ...state,
          isLogin: false,
        };

      default:
        return state;
    }
  }
}
