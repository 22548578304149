import React, { useRef, useState, useEffect } from "react";

import Toolbar from "./toolbar";
import SideBar from "./sidebar";
import { isMobile } from "react-device-detect";

export default function NavBar(props) {
  const [opaqueStyle, setOpaqueStyle] = useState('');

  useEffect(() => {
    window.addEventListener("scroll", listenToScroll);
    return () =>
       window.removeEventListener("scroll", listenToScroll);
  }, []);

  const listenToScroll = () => {
    let heightToHideFrom = 300;
    const winScroll = document.body.scrollTop || document.documentElement.scrollTop;
    // console.log("winScroll > heightToHideFrom", winScroll, heightToHideFrom);
    if (winScroll > heightToHideFrom) {
      setOpaqueStyle('bg-zinc-300 bg-opacity-20');
    } else { // to limit setting state only the first time
      setOpaqueStyle('');
    }
  };

  const height = props.height || " h-24 ";
  const headerStyle = isMobile ? `${height} inset-0 z-10 max-w-full rounded-none py-2 px-4 lg:px-8 lg:py-4` : "";
  const headerClassName = props.headerClassName || "text-black";
  return <div className={`fixed top-0 left-0 w-full z-50 ${opaqueStyle}`}>
    <div className={headerStyle}></div>
    <Toolbar headerClassName={headerClassName} onSideBarVisible={props.onSideBarVisible}/>
    <SideBar headerClassName={headerClassName} onSideBarVisible={props.onSideBarVisible} />
    {props.children}
    </div>
}
