import { useRef, useEffect, useState } from "react";
import { useDispatch } from "react-redux";  //Requesting and Storing Data
import { useSelector } from "react-redux";  //Reading Data
import "./styles.css";

import { isMobile } from 'react-device-detect';
import { animated, useSpring } from "react-spring";
import Button from "../../form-components/button";
import { Accordion, AccordionBody, AccordionHeader, Card, CardBody, Checkbox, Collapse, List, ListItem, ListItemPrefix } from "@material-tailwind/react";
import CText from "../../../../../utilities/typography/ctext";

export default function FilterBar(props) {
    console.log("FilterBar", props);
    // Get Category List
    const productStore = useSelector((state) => state.products);
    // console.log("ProductListing -> productsStore", productStore);
    const categoryList = productStore.productCategoryList.data || [];
    const [selectedCategoryList, setSelectedCategoryList]= useState(categoryList);

    // console.log("FilterBar", props);
    const [show, setShow] = useState(false);
    const [width, setWidth] = useState(0);
    
    const [filterCategoryShow, setFilterCategoryShow] = useState(false);

    // console.log("productsStore", productStore);
    
    const rightMenuAnimation = useSpring({
        opacity: show ? 1 : 0,
        transform: show ? `translateX(0)` : `translateX(100%)`
      });

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        setTimeout(() => setFilterCategoryShow(true), 2600);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    function onCategoryFilterChange(idx) {
        const newSelectedCategoryList = JSON.parse(JSON.stringify(selectedCategoryList));
        // console.log ("*****", idx);
        newSelectedCategoryList[idx].isChecked = !newSelectedCategoryList[idx].isChecked;
        // console.log ("*****", idx);
        setSelectedCategoryList([...newSelectedCategoryList]);
    }

    
    const FilterListItem = (props) => {
        const item = props.item;
        const idx = props.idx;
        console.log("ListItem -> props",props, item.isChecked);
          return <div className="px-0 py-1 flex items-center w-full cursor-pointer " onClick={() => onCategoryFilterChange(idx)}>
            <div className="mr-3">
              <Checkbox id="filter-category-list" ripple={false} checked={item.isChecked} onChange={() => onCategoryFilterChange(idx)}
                className="hover:before:opacity-0" color="gray" containerProps={{ className: "p-0 bg-white" }} />
            </div>
            <CText>{item.category_name}</CText>
          </div>
    }

    function setShowHide(show) {
        setShow(show);
    }
      
    function applyFilter () {
        const filter = {
            categoriesSelected: selectedCategoryList.filter(c => c.isChecked)
        }
        if (props.onApplyFilter) props.onApplyFilter(filter);
    }

    console.log ("*****", selectedCategoryList)

    const filterLink = isMobile ? "absolute top-10 right-4 h-32 mt-5 z-10 uppercase" : "absolute right-24 h-32 mt-16 z-10 uppercase";
    const menuTextColorClass = props.menuTextColorClass || " text-white ";
    return (<div>
        <div className={filterLink}>
            {isMobile ? <a onClick={() => setShowHide(true)} className={`material-symbols-outlined cursor-pointer ${menuTextColorClass}`}>filter_list</a>
                : <Button onClick={() => setShowHide(true)}>Filter</Button>}
        </div>
        {show &&
        <animated.div style={{ opacity: props.opacity }} className="overlay" >
            <animated.div  className="drawer menu--right w-fit" style={{...rightMenuAnimation, width: isMobile ? "85vw" : "468px", height: "100vh"}}>
                <div style={{ padding: "20px", cursor: "pointer" }} className=" mb-8" >
                    <span className="material-symbols-outlined" onClick={() => setShowHide(false)} style={{ fontSize: "24px", float: "left" }} >
                        close
                    </span>
                </div>
                <Accordion open={filterCategoryShow} className=" ml-6">
                    <AccordionHeader onClick={()=>setFilterCategoryShow(!filterCategoryShow)} className="py-3">
                        <div className=" float-left uppercase"><CText>Filter by Category</CText></div>
                        {filterCategoryShow
                        ? <a className={`material-symbols-outlined cursor-pointer text-black float-right`}>remove</a> 
                        : <a className={`material-symbols-outlined cursor-pointer text-black float-right`}>add</a>}
                    </AccordionHeader>
                    <AccordionBody>
                        <div>
                            <div className='grid grid-cols-2 gap-2'>
                                {selectedCategoryList.map((item, key) => <FilterListItem idx={key} item={item} />)}
                            </div>
                        </div>
                    </AccordionBody>
                </Accordion>
                <div className=" cursor-pointer sticky bottom-0 p-5 bg-white" >
                    <Button className="uppercase" onClick={applyFilter}>apply filter</Button>
                </div>
            </animated.div>
            <div className="fill" onClick={() => setShowHide(false)} />
        </animated.div>}
        {isMobile ? <></> :
            <div className="absolute top-10 right-10 h-32 mt-8 z-10 uppercase">
                <a className={`material-symbols-outlined cursor-pointer ${menuTextColorClass}`} onClick={props.onLayoutChange}>grid_view</a>
            </div>
        }
    </div>
    );
}