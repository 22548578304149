import { useDispatch } from "react-redux";  //Requesting and Storing Data
import { useSelector } from "react-redux";  //Reading Data
import { isMobile } from 'react-device-detect';
import SText from "../../../../../utilities/typography/stext";
import AuthAction from "../../../../../store/Auth/AuthAction";
import NText from "../../../../../utilities/typography/ntext";

import { enqueueSnackbar } from "notistack";
import { useGoogleLogin  } from "@react-oauth/google";

export default function Toolbar(props) {
    
    const dispatch = useDispatch();
    
    const authStore = useSelector((state) => state.auth);
    
    const isLogin = authStore.isLogin || false;
    const userDetails = authStore.userDetails || {};

    console.log("Toolbar isLogin:", isLogin, " userDetails:", userDetails);

    const googleLogin = useGoogleLogin({ 
        onSuccess: async tokenResponse => {
            console.log("tokenResponse", tokenResponse);
            const headers = {
                'Authorization': `Bearer ${tokenResponse.access_token}`, 
            };
                
            var requestOptions = {
                method: 'GET',
                headers: headers,
                redirect: 'follow'
            };
        
            fetch("https://people.googleapis.com/v1/people/me?personFields=names,emailAddresses", requestOptions)
                .then(response => response.text())
                .then(profile => {
                    console.log(profile);
                    if (profile !== null) {
                        // console.log("Before profileJson", profile);
                        if (!profile) {
                            enqueueSnackbar("Failed to login using Google, Please try again!", { variant: 'error' })
                        }
                        else {
                            const profileJson = JSON.parse(profile);
                            console.log("After profileJson", profileJson);
                
                            let firstName = "";
                            let lastName = "";
                            if (profileJson.names && profileJson.names.length > 0) {
                                firstName = profileJson.names[0].givenName;
                                lastName = profileJson.names[0].familyName;
                            }

                            let email = "";
                            if (profileJson.emailAddresses && profileJson.emailAddresses.length > 0) {
                                // const emailVerified = profileJson.emailAddresses[0].metadata.verified;
                                email = profileJson.emailAddresses[0].value;
                            }
                
                            console.log("firstName, lastName, email", firstName, lastName, email);
                            if (firstName !== "" && lastName !== "" && email !== "") {
                                senLoginRequest(firstName, lastName, email, profile);
                            }
                            else    {
                                enqueueSnackbar("Unable to get information about your Google login, Please try again!", { variant: 'error' });
                            }
                        }
                    }
                })
                .catch(error => {
                    enqueueSnackbar("Failed to login using Google, Please try again!", { variant: 'error' });
                    console.log('error', error);
                });
        }
    });

    const senLoginRequest = async (firstName, lastName, email, profile) => {
        console.log("senLoginRequest", firstName, lastName, email);
        dispatch(AuthAction.login({ 
            "type": "GOOGLE", 
            "user_name": email, 
            "email": email, 
            "first_name": firstName, 
            "last_name": lastName, 
            "social_media_response_json": profile
        }));
    }

    const loginLink = isMobile ? "absolute top-0 right-28 h-32 mt-5 uppercase" : "absolute top-0 right-40 h-32 mt-5 uppercase";
    const helpLink = isMobile ? "absolute top-0 right-16 h-32 mt-5 uppercase" : "absolute top-0 right-24 h-32 mt-5 uppercase";
    const cartLink = isMobile ? "absolute top-0 right-4 h-32 mt-5 uppercase" : "absolute top-0 right-10 h-32 mt-5 uppercase";

    const headerClassName = props.headerClassName || " text-white ";
    return (<>
            {isMobile 
                ?  <a href="/"><img src="/images/logo.png" className="absolute left-0 top-0 h-12 mt-2" style={{marginLeft: "60px"}}/></a>
                : <a href="/"><img src="/images/logo.png" className="absolute left-0 top-0 h-20 mt-5" style={{marginLeft: "100px"}}/></a>
            }
            {isLogin ? <div className={loginLink}><NText className="font-semibold">Hello {userDetails.first_name} !</NText></div> :
                <div className={loginLink} onClick={googleLogin}>
                 {/* <GoogleLogin onSuccess={credentialResponse => { console.log(credentialResponse); }} onError={() => { console.log('Login Failed'); }} /> */}
                    {isMobile ? <a className={`material-symbols-outlined cursor-pointer ${headerClassName}`}>lock</a> 
                    : <SText upper={true}><a className={`w-16 right-0 cursor-pointer ${headerClassName}`}>Log in</a></SText>}
                </div>
            }
            {<div className={helpLink}>
                    {isMobile ? <a className={`material-symbols-outlined cursor-pointer ${headerClassName}`}>help</a> 
                        : <SText upper={true}><a className={`w-16 right-0 cursor-pointer ${headerClassName}`}>Help</a></SText>}
                </div>
            }
            <div className={cartLink}>
                <a className={`material-symbols-outlined cursor-pointer ${headerClassName}`}>shopping_cart</a> 
            </div>
        </>
    );
}