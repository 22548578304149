import React, { useRef, useState, useEffect } from "react";

import { isMobile } from "react-device-detect";
import Button from "../../form-components/button";
import CText from "../../../../../utilities/typography/ctext";
import SText from "../../../../../utilities/typography/stext";

export default function ProductRightPanel(props) {
//   console.log("ProductRightPanel", props);

  const product = props.product || {};
  const variant = props.selectedProductVariant || {};

  return <div className="mt-24 pt-5">
      <div>
          <div style={{height: '500px'}} className={"container"}>              
              <div className="row flex flex-row ">
                  <CText className="uppercase">{product.product_title}</CText>
                  <div><a className={`material-symbols-outlined cursor-pointer text-black float-right m-2 mr-6`}>bookmark_add</a></div>
              </div>
              
              <div className="row flex flex-row mt-6">                
                  <SText className="uppercase font-semibold">Price: </SText>
                  <SText><span className="material-symbols-outlined text-xs" onClick={() => {}} >currency_rupee</span>{variant.sell_price}</SText>
              </div>
              <div className="row flex flex-row mt-2">
                  <CText className="uppercase font-semibold">Material:</CText>
                  <CText className="uppercase ml-1">{variant.material} </CText>
              
                  <CText className="uppercase font-semibold ml-3">Color:</CText>
                  <CText className="uppercase ml-1">{variant.color} </CText>
              
                  <CText className="uppercase font-semibold ml-3">Length:</CText>
                  <CText className="uppercase ml-1">{variant.length_cm} </CText>
              </div>
              <div className="row mt-10 pr-8">
                <Button className="w-full">ADD TO CART</Button>  
              </div>
              {/* <div className="row mt-10">
                  <div className="align-self-center text-justify">
                      <CText>{product.product_description}</CText>
                  </div>              
              </div> */}
          </div>
      </div>
  </div>
}
