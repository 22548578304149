import ProductsAction from "./ProductsAction";

const initState = {
  productCategoryList: { data: [] },       //List of all Category
  selectedCategoryItem: {},                 //Category Selected
};
export default class ProductsReducer {
  static initialState = initState;

  static reducer(state = ProductsReducer.initialState, action) {
    if (action.error) {
      return state;
    }
    switch (action.type) {
      case ProductsAction.SAVE_CATEGORY_DATA:
        console.log("state.productCategoryDetails", state, state.productCategoryList);
        return { ...state, productCategoryList: action.payload };
      case ProductsAction.SAVE_SELECTED_CATEGORY:
        console.log("state.SAVE_SELECTED_CATEGORY_LOCAL", state, state.selectedCategoryItem);
        return { ...state, selectedCategoryItem: action.payload };
      default:
        return state;
    }
  }
}
