import React, { useRef, useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import queryString from 'query-string';

import { isMobile } from "react-device-detect";
import NavBar from "../navbar";
import ProductsEffect from "../../../../store/Products/ProductsEffect";

import ProductDescriptionBottomSheet from "./product-mobile-bottom-sheet";
import ProductImageScroller from "./product-image-scroll";
import ProductLeftPanel from "./product-left-panel";
import ProductRightPanel from "./product-right-panel";
import NetworkError from "../network-error";
import ErrorAction from "../../../../store/Error/ErrorAction";
import YouMayLike from "./you-may-like/index,";

export default function ProductVariant() {
  const dispatch = useDispatch();
  
  console.log("ProductVariant");
  const [isLoading, setIsLoading] = useState(false);
  const [paginationAttributes, setPaginationAttributes] = useState({ pageNo : 1, pageSize : 10, orderBy: 'ASC' });

  const [product, setProduct] = useState(null);
  const [selectedProductVariant, setSelectedProductVariant] = useState(null);

  let params = queryString.parse(window.location.hash.substring(window.location.hash.indexOf('?') + 1));
  // console.log("params", params, window.location.hash);
  const productKey = params.pkey;
  const variantKey = params.vkey;
  // console.log("productKey, variantKey", productKey, variantKey);
    
  useEffect(() => {
    getProduct(productKey || null);
  }, []);

  const getProduct = async (productKey) => {
    setIsLoading(true);
    const result = await ProductsEffect.productList({product_key: productKey, ...paginationAttributes});
    // console.log("result -------->", result);
    if (result.status === 0 && result.message === "Error requesting data") { //Network Error
      dispatch(ErrorAction.showApiError(true));
    }
    else if (result.status == "success" && result.data) {
      setSelectedProductVariant(result.data[0].variantList[0]);
      setProduct(result.data[0]);
    }
    setIsLoading(false);
  }

  const contentTopStyle = isMobile ? "grid grid-cols-12 gap-4 mt-18 p-1 pt-5 mb-10" : "grid grid-cols-12 gap-4 mt-10 p-5 mb-10";

  // return <div style={{ overflow: '-moz-scrollbars-vertical', overflowY: 'scroll', height: "100%"}}>
  return <div>
    {product &&
    <div className={contentTopStyle}>
        <div className="col-span-12 lg:col-span-3 m-0 mt-20">
          {isMobile ? 
            <ProductDescriptionBottomSheet product={product} onVariantChanged={(variant)=>setSelectedProductVariant(variant)} /> : 
            <ProductLeftPanel product={product} onVariantChanged={(variant)=>setSelectedProductVariant(variant)} />
          }
        </div>
        <div className="col-span-12 lg:col-span-6 m-0">
          <ProductImageScroller product={product} selectedProductVariant={selectedProductVariant} />
        </div>
        <div className={`col-span-12 lg:col-span-3 m-0 ${isMobile ? "mt-20" : "mt-0"}`}>
          {!isMobile && <ProductRightPanel product={product} selectedProductVariant={selectedProductVariant} />}
        </div>
    </div>
    }
    {!isMobile && <div className="w-full"><YouMayLike product={product} /></div>}
  </div>
}
