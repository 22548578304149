import HttpUtility from '../utils/http/HttpUtility';
import { UserInquiry } from "../utils/http/ApiUrls";


export default class CustomerEffect {
  static async saveProductInquiry(inquiryData) {
    // console.log("saveProductInquiry", inquiryData);
    return await HttpUtility.post(UserInquiry, inquiryData);
  }
}
