import React, { useState } from "react";
import { useDispatch } from "react-redux";  //Requesting and Storing Data
import { enqueueSnackbar } from "notistack";

import CustomerAction from "../../../../../../store/Customer/CustomerAction";
import Button from "../../../form-components/button";

function InquiryForm(props) {
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(true);

    //Form Fields
    const [inquiryData, setInquiryData] = useState({
        email: "",
        phone: "",
        full_name: "",
        message: "",
        category_key: ""
    });
    
    const onFieldChange = (field, value) => {
        // console.log("inquiryData", inquiryData, field, value);
        setInquiryData({...inquiryData, [field]: value});
    }
    
    const submitInquiry = async () => {
        // console.log("inquiryData", inquiryData);
        setIsLoading(true);        
        const result = await dispatch(await CustomerAction.saveProductInquiry(inquiryData));
        if (result.status == "success") {
            enqueueSnackbar('Your inquiry has been submitted successfully! Our representative will back to you soon.', { variant: 'success' })
        }
        else {
            enqueueSnackbar("Error saving your Inquiry, Please try again if ypu have not submitted any inquiry previously!", { variant: 'error' })
        }
        setIsLoading(false);
        if (props.onClose) props.onClose();
    }

    return <div>
        <div className="main-form">
            <p>
                <label> <span className="error" id="err-name">please enter name</span></label>
                <input type="text" name="name" value={inquiryData.full_name} size="40" 
                    className="" aria-invalid="false" placeholder="Your Name *" required 
                    onChange={(event) => onFieldChange('full_name', event.target.value)}/>
            </p>
            <p>
                <label> 
                    <span className="error" id="err-email">please enter e-mail</span>
                    <span className="error" id="err-emailvld">e-mail is not a valid format</span>
                </label>
                <input type="email" name="email" id="email" value={inquiryData.email} size="40" 
                    className="" aria-invalid="false" placeholder="Your Email *" required 
                    onChange={(event) => onFieldChange('email', event.target.value)}/>
            </p>
            <p>
                <label></label>
                <textarea name="message" id="message" cols="40" rows="10" className="" 
                aria-invalid="false" placeholder="Message..." required
                value={inquiryData.message}
                onChange={(event) => onFieldChange('message', event.target.value)}>
                </textarea>
            </p>
            <p><Button onClick={() => submitInquiry()} >Send Message</Button></p>
            <div className="clear"></div>   
            <div className="error" id="err-form">There was a problem validating the form please check!</div>
            <div className="error" id="err-timedout">The connection to the server timed out!</div>
            <div className="error" id="err-state"></div>
        </div>
        <div className="clear"></div>
        <div id="ajaxsuccess">Successfully sent!!</div>
        <div className="clear"></div>
    </div>
}

export default InquiryForm;