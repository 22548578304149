import { Login, SocialLogin } from "../utils/http/ApiUrls";
import HttpUtility from "../utils/http/HttpUtility";

export default class AuthEffect {
  // static async requestGeoLocation(params) {
  //   let response = await HttpUtility.post(GeoLocation, params);

  //   return response;
  // }

  
  static async login(data) {
    console.log("Login:::: ", Login, data);
    let response = await HttpUtility.post(Login, data);

    return response;
  }

  static async saveRegDetails(data) {
    console.log("SaveRegDetails:::: ", SaveRegDetails, data);
    let response = await HttpUtility.post(SaveRegDetails, data);

    return response;
  }

}
