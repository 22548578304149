import React, { useState, useEffect } from "react";
import "./styles.css";
import { animated, useTransition } from "react-spring";
import { isMobile } from 'react-device-detect';

import { useDispatch } from "react-redux";  //Requesting and Storing Data
import { useSelector } from "react-redux";  //Reading Data

import ProductsAction from "../../../../../store/Products/ProductsAction";

import {
  Tabs,
  TabsHeader,
  TabsBody,
  Tab,
  TabPanel,
  List, 
  ListItem
} from "@material-tailwind/react";
import CText from "../../../../../utilities/typography/ctext";
import SText from "../../../../../utilities/typography/stext";

export default function SideBar(props) {
  const dispatch = useDispatch();

  const productStore = useSelector((state) => state.products);
  // console.log("productsStore", productStore);
  const categoryList = productStore.productCategoryList.data;
  const selectedCategoryItem = productStore.selectedCategoryItem;

  const [show, setShow] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);
  

  const transitions = useTransition(show, null, {
    from: { opacity: 0, width: (isMobile ? width  : 468), transitionDuration: '5s' },
    enter: { opacity: 1, width: (isMobile ? width  : 468), transitionDuration: '5s' },
    leave: { opacity: 1, width: (isMobile ? width  : 468), transitionDuration: '5s' },
    // from: { position: "fixed", opacity: 0, width: 0 },
    // enter: { opacity: 1, width: (isMobile ? width  : 468) },
    // leave: { opacity: 0, width: 0 }
  });

  function handleWindowSizeChange() {
      setWidth(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, []);

  function setShowHide(show) {
    setShow(show);
    if (props.onSideBarVisible) props.onSideBarVisible(show);
  }

  const setSelectedCategoryItem = (category) => {
    dispatch(ProductsAction.setSelectedCategoryItem(category));
  }

  const ListItem = (props) => {
    // console.log("ListItem -> props",props);
    return <CText className="uppercase">{props.children.category_name}</CText>
  }

  // console.log("Sidebar -> show, categoryList", show, props, categoryList, selectedCategoryItem);

  const activeItemKey = selectedCategoryItem.category_key;
  const headerClassName = props.headerClassName || " text-white ";
  return (
    <div className="sidebar absolute left-0 top-0" style={{zIndex: 99}}>
        <div style={{ padding: "20px", cursor: "pointer" }} >
            <span className={`material-symbols-outlined ${headerClassName}`} 
              onClick={() => setShowHide(true)} style={{ fontSize: "24px", float: "left"}} >
                menu
            </span>
        </div>
        {/* <SText upper={true}>Hintage - A Heritage from Hind!</SText>
        <CText>Select your interests and receive the latest news and trends each week.</CText> */}
        {transitions.map(
            ({ item, key, props }) =>
            item && (
                <animated.div
                key={key}
                style={{ opacity: props.opacity }}
                className="overlay"
                >
                  <animated.div style={{ width: props.width }} className="drawer">
                    <div style={{ padding: "20px", cursor: "pointer" }} >
                      <span className="material-symbols-outlined" onClick={() => setShowHide(false)} style={{ fontSize: "24px", float: "left"}} >
                        close
                      </span>
                    </div>
                  {show && 
                      <Tabs value={activeItemKey} className="mt-7 mr-3">
                        <TabsHeader
                          className="rounded-bg-transparent p-0"
                          indicatorProps={{
                            className: "bg-transparent border-b-2 border-black shadow-none rounded-none w-3 m-auto justify",
                          }}
                        >
                          {categoryList.map(item => (
                            (item.sub_category_list && item.sub_category_list.length > 0) ?
                            <Tab 
                              key={item.category_key} value={item.category_key} 
                              onClick={() => setSelectedCategoryItem(item)}
                              >
                              <CText className="uppercase" >{item.category_name}</CText>
                            </Tab> : <></>
                          ))}
                        </TabsHeader>
                        <TabsBody>
                          {categoryList.map((item, key) => {
                            return item.sub_category_list && item.sub_category_list.length > 0 ?
                              <TabPanel key={item.category_key} value={item.category_key}>
                                <List className="ml-6">
                                  {item.sub_category_list.map((subItem, key) => 
                                    <a href={`/#/product-listing?ckey=${item.category_key ==="*" ? subItem.parent_category_key : item.category_key}&sckey=${subItem.category_key}`} 
                                      className="mt-1">
                                      <ListItem key={key} parent={item}>{subItem}</ListItem>
                                    </a>
                                  )}
                                </List>
                              </TabPanel>
                              :
                              <></>
                            })}
                        </TabsBody>
                      </Tabs>
                  }
                </animated.div>
                <div className="fill" onClick={() => setShowHide(false)} />
                </animated.div>
            )
        )}
    </div>
  );
}
