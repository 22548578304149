import CommonAction from "../Common/CommonAction";
import ActionUtility from "../../utilities/ActionUtility";
import AuthEffect from "./AuthEffect";
import Headers from "../utils/http/Headers";

export default class AuthAction {
  static SAVE_USER_DATA = "AuthAction.SAVE_USER_DATA";
  static LOGIN = "AuthAction.LOGIN";
  static LOGOUT = "AuthAction.LOGOUT";

  static setLoader() {
    return async (dispatch, getState) => {
      dispatch(CommonAction.showLoader());
    };
  }

  static saveUserDetails(data) {
    return async (dispatch, getState) => {
      dispatch(ActionUtility.createAction(AuthAction.SAVE_USER_DATA, data));
    };
  }

  static logout() {
    return async (dispatch, getState) => {
      dispatch(ActionUtility.createAction(AuthAction.LOGOUT, {}));
    };
  }

  static login (data) {
    console.log ("Action", data);    
    return async (dispatch, getState) => {  
      console.log ("After Action", data);
      const result = await AuthEffect.login(data);
      if (result.status == "success") {
        Headers.setSessionToken(result.data.token);
        dispatch(ActionUtility.createAction(AuthAction.LOGIN, result.data));
      }
    };
  };

  static saveRegDetails (data) {
    console.log ("Action", data);    
    return async (dispatch, getState) => {  
      console.log ("After Action", data);
      const result = await AuthEffect.saveRegDetails(data);
      if (result.status == "success") {
        Headers.setSessionToken(result.data.token);
        dispatch(ActionUtility.createAction(AuthAction.LOGIN, result.data));
      }
    };
  };
}
