import React from "react";
import Home from "../page/zara/home";
import ncNanoId from "../../utilities/ncNanoId";
import ProductListing from "../page/zara/product-listing";
import ProductVariant from "../page/zara/product-variant";
import TermsConditions from "../page/zara/footer/terms-conditions";
import PrivacyPolicy from "../page/zara/footer/privacy-policy";
import About from "../page/zara/footer/about";
import Refund from "../page/zara/footer/refund";
import Return from "../page/zara/footer/return";
import Shipping from "../page/zara/footer/shipping";
import ContactUs from "../page/zara/footer/contact-us";

// export const SERVICE_MENU = [
//     { id: ncNanoId(), href: "/services-desing-planning", name: "Design & Planning", component: <OfferedServices serviceId="1" /> },
//     { id: ncNanoId(), href: "/services-exterior-design", name: "Exterior Design", component: <OfferedServices serviceId="2" /> },
//     { id: ncNanoId(), href: "/services-custom-solutions", name: "Custom Solutions", component: <OfferedServices serviceId="3" /> },
//     { id: ncNanoId(), href: "/services-furniture-decor", name: "Furniture & Decor", component: <OfferedServices serviceId="4" /> },
//     { id: ncNanoId(), href: "/services-concept", name: "Creating Concept", component: <OfferedServices serviceId="5" /> },
//   ];

export const NAVIGATION_MENU = [
  // { id: ncNanoId(), href: "/products-all", name: "Products", children: PRODUCTS_MENU, component: <Product /> },
  { id: ncNanoId(), href: "/product-variant", name: "Product Varaint", component: <ProductVariant />, footerMobile: false },
  { id: ncNanoId(), href: "/product-listing", name: "Product Listing", component: <ProductListing />  },
  { id: ncNanoId(), href: "/contact", name: "Contact us", component: <ContactUs />  },
];

export const FOOTER_ROUTES = [
  { id: ncNanoId(), href: "/terms-conditions", name: "Terms of Use", component: <TermsConditions /> },
  { id: ncNanoId(), href: "/privacy-policy", name: "Privacy Policy", component: <PrivacyPolicy /> },
  { id: ncNanoId(), href: "/about-us", name: "Our Story", component: <About /> },
  { id: ncNanoId(), href: "/refund-policy", name: "Refund Policy", component: <Refund /> },
  { id: ncNanoId(), href: "/return-policy", name: "Return Policy", component: <Return /> },
  { id: ncNanoId(), href: "/shipping-policy", name: "Shipping Policy", component: <Shipping /> },
];
