import { isMobile } from "react-device-detect";
import { useDispatch, useSelector } from "react-redux";
import Button from "../form-components/button";
import ErrorAction from "../../../../store/Error/ErrorAction";

import { useHistory } from "react-router-dom";
import { useEffect } from "react";

export default function NetworkError(props) {
    const dispatch = useDispatch();
    const history = useHistory();

    // const errorStore = useSelector((state) => state.error);
    // console.log("errorStore", errorStore);
    // const apiError = errorStore.apiError;    
    
    // useEffect(() => {
    //     dispatch(ErrorAction.showApiError(false));
    // }, [apiError]);

    return <div className="absolute rounded-r-3xl shadow-2xl">
        <div className="bg-white h-screen w-screen flex flex-wrap justify-center content-end md:content-center items-end md:items-center relative z-10">
            <div className="p-6 text-center ">
                <h2 className="uppercase text-xl lg:text-5xl font-black">We're sorry, but something went wrong.</h2>
                <p className="mt-3 uppercase text-sm lg:text-base text-gray-900">Please refresh this page, or you may try again after sometime.</p>
                <div className="mt-10">
                    <Button href="/" onClick={() => {
                        dispatch(ErrorAction.showApiError(false));
                        history.push("/");
                    }}>Back To Hintage Home</Button>
                </div>
            </div>
        </div>
    </div>
};