import ReactReadMoreReadLess from "react-read-more-read-less";
import "./styles.css";

export default function ReadMore(props) {
    return <ReactReadMoreReadLess
            charLimit={props.charLimit}
            // readMoreText={"View more ▼"}
            // readLessText={"View less ▲"}
            readMoreText={"View more ▼"}
            readLessText={"View less ▲"}
            readMoreClassName={props.color && props.color == "white" ? `read-more-less--more-white` : `read-more-less-more-white`}
            readLessClassName={props.color && props.color == "white" ? `read-more-less-less-white` : `read-more-less-less-white`}
        >
            {props.text}
        </ReactReadMoreReadLess>
}