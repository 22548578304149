import React, { useState } from "react";

import { isMobile } from "react-device-detect";
import { Helmet } from "react-helmet-async";
import NText from "../../../../../utilities/typography/ntext";
import SText from "../../../../../utilities/typography/stext";
import HText from "../../../../../utilities/typography/htext";

export default function Refund() {

  return (
      <div>
            <Helmet>
              <title>Hintage Refund - A Heritage from Hind</title>
              <meta
                name="description"
                content="Hintage Refund - A Heritage from Hind"
              />
            </Helmet>
            <div className="container text-black p-6 mt-32">
                <div className="row">
                    <HText className="mb-12 text-2xl uppercase text-center ">Refund Policy</HText>
                    <div className="text-justify">
                        <NText className="pt-2 pb-6">
                            Hintage strives to ensure that every transaction on our website is seamless. We take great care in delivering our products and adhere to the highest quality standards.<br /><br />
                            As a policy, we do not offer refunds on products that are delivered in perfect condition as per the order placed. However, if the product is wrongfully delivered (product doesn't match the item in the order confirmation) or has a genuine quality/manufacturing defect or is damaged during shipping, we are open to extending a return or exchange for your order. In case of unavailability of the same product, Hintage will proceed with a full refund. The refund will be processed to your original method of payment.<br />
                            In the rare scenario where a product gets damaged during transit, we take full accountability for the damage, allowing you to shop without any concerns about shipping damage.
                        </NText>
                        <SText className="uppercase">Eligibility for Refund</SText>
                        <NText className="pt-2 pb-6">
                            <ul className=" list-decimal ml-6">
                                <li>
                                    Handmade & Handpainted products may have minor imperfections in terms of color shades, texture, painting, polish, finish, shape, weight, size, and may also differ insignificantly from each other, making them unique & special. Refunds will not be applicable in such scenarios.
                                </li><li>
                                    Wooden products may develop hairline cracks with changes in temperature, climate, and over time. This is a natural process. Refunds will not be applicable in such scenarios.
                                </li><li>
                                Antiques & Vintage Collections are not machine-made or machine-finished or newly manufactured. There may be multiple imperfections, damage, dents, color blemishes, etc. We retain the original old look of the way the product was collected, and we do not repair or modify any imperfections to maintain the authenticity of the time & source. Refunds will not be applicable in such scenarios.
                                </li><li>
                                    Handmade or Handpainted or Handcrafted or Vintage or Antique products will not be exactly similar to each other in terms of color shades, texture, painting, polish, finish, shape, weight, size. The photograph may not be an exact representation of the product you receive, but of course, similar. Any dispute and refunds will not be applicable in such scenarios.<br />
                                    Any dispute or concern regarding any product should be brought to the attention of our Customer Care within 3 Business Days from the delivery. After this timeline, refunds will not be applicable.
                                </li><li>
                                    The refund policy will be applicable to products returned to us without any damage – in their original condition.
                                </li><li>
                                    The refund policy will be applicable to returned products that are unused, unwashed, and have all their original packaging and tags intact.
                                </li><li>
                                    In cases where a customer has provided an incorrect or incomplete delivery address and our shipping partner fails to deliver after 3 attempts, or the recipient refuses to accept the delivery, refunds will not be applicable. The customer will need to pay additional shipping charges to initiate the dispatch process again from our end.
                                </li><li>
                                    For international orders, if a customer refuses to pay the duty as mandated by the respective country of shipment and doesn't collect their order from the shipping agency, refunds will not be applicable.
                                    <br /><br />
                                    The above eligibility for refunds is applicable for both domestic and international purchases.
                                </li>
                            </ul>
                        </NText>
                        <SText className="uppercase">PROCESS TO REFUND</SText>
                        <NText className="pt-2 pb-6">
                            <ul className=" list-decimal ml-6">
                                <li>
                                    Always record the unboxing video and photographs of the products. Check the products carefully. If incorrect, damaged, or genuine quality/manufacturing defects are found, contact Hintage Customer Care within 48 hours of delivery with the unboxing video and photographs of the concerned product.<br />
                                    <b>Customer Care:</b> <a href="mailto:care@hintage.in">care@hintage.in</a><br />
                                    <b>Phone Number with WhatsApp:</b> <a href="tel:+919892983643">+(91) 9892983643</a> between 11am to 18pm Indian Standard Time<br />                                
                                    <b>Mailing Address:</b> Hintage, B/5, Bal smruti, Murar Road, Mulund (West), Mumbai - 400080, Maharashtra. India 
                                </li><li>
                                    The Hintage team will review your concern and respond with a solution within 7 Business Days.<br />
                                </li><li>
                                    Return or exchange will be the first solution offered to you if accepted by Hintage.<br />
                                </li>
                            </ul>
                        </NText>
                    </div>
                </div>
            </div>
        </div>
  );
}