import ApiService from "./ApiService";

const RequestMethod = {
  Get: "GET",
  Post: "POST",
  Put: "PUT",
  Delete: "DELETE",
  Options: "OPTIONS",
  Head: "HEAD",
  Patch: "PATCH",
};

export default class HttpUtility {
  static async get(endpoint, params, requestConfig) {
    // return ApiService.get(url);
    const paramsConfig = params ? { params } : undefined;

    return HttpUtility._request(
      {
        url: endpoint,
        method: RequestMethod.Get,
      },
      {
        ...paramsConfig,
        ...requestConfig,
      }
    );
  }

  static async post(endpoint, data, conf) {
    let config = data ? { data } : undefined;

    if (conf) {
      config = { ...config, ...conf };
    }
    return HttpUtility._request(
      {
        url: endpoint,
        method: RequestMethod.Post,
      },
      config
    );
  }

  static async put(endpoint, data) {
    const config = data ? { data } : undefined;

    return HttpUtility._request(
      {
        url: endpoint,
        method: RequestMethod.Put,
      },
      config
    );
  }

  static async delete(endpoint, data) {
    const config = data ? { data } : undefined;

    return HttpUtility._request(
      {
        url: endpoint,
        method: RequestMethod.Delete,
      },
      config
    );
  }
  static async _request(restRequest, config) {
    if (!restRequest.url) {
      console.error(`Received ${restRequest.url} which is invalid for a endpoint url`);
    }

    const axiosRequestConfig = {
      ...config,
      method: restRequest.method,
      url: restRequest.url,
      headers: {
        ...config?.headers,
      },
    };

    const [axiosResponse] = await Promise.all([
      ApiService(axiosRequestConfig),
      HttpUtility._delay(),
    ]);

    return axiosResponse;
  }
  static _delay(duration = 250) {
    return new Promise((resolve) => setTimeout(resolve, duration));
  }
}
