import {combineReducers} from 'redux';
import AuthAction from './Auth/AuthAction';
import AuthReducer from './Auth/AuthReducer';

import CommonReducer from './Common/CommonReducer';
import ProductsReducer from './Products/ProductsReducer';
import CustomerReducer from './Customer/CustomerReducer';
import ErrorReducer from './Error/ErrorReducer';


const reducers = {
  common: new CommonReducer().reducer,
  auth: AuthReducer.reducer,
  products: ProductsReducer.reducer,
  customer: CustomerReducer.reducer,
  error: ErrorReducer.reducer
};

const appReducer = combineReducers(reducers);
const rootReducer = (state, action) => {
  if (action.type === AuthAction.LOGOUT) {
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};

export default rootReducer;

