import ActionUtility from "../../utilities/ActionUtility";

export default class ErrorAction {
  static API_ERROR = "ErrorAction.API_ERROR";

  static showApiError = (show) => {
      return async (dispatch, getState) => {
        console.log("showApiError", show);
        dispatch(ActionUtility.createAction(ErrorAction.API_ERROR, show));
      };
  }
}
