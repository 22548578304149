import React, { useRef, useState } from "react";
import { useDispatch } from "react-redux";  //Requesting and Storing Data

// react-router-dom components
import { useHistory } from "react-router-dom";

import { isMobile } from "react-device-detect";
import ProductsAction from "../../../../../store/Products/ProductsAction";
import CText from "../../../../../utilities/typography/ctext";

export default function ProductMenu(props) {
    console.log("ProductMenu --->", props);
    const dispatch = useDispatch();
    const history = useHistory();
    
    const [showArrow, setShowArrow] = useState(isMobile ? true : false);

    const leftArrowRef = useRef(null);
    const rightArrowRef = useRef(null);

    const categoryList = props.categoryList || [];
    const categoryKey = props.categoryKey || '*';

    const setSelectedCategoryItem = (category) => {
        dispatch(ProductsAction.setSelectedCategoryItem(category));
        history.push(`/product-listing?ckey=${category.category_key}&sckey=*`);
    }

    const handleArrowClick = (left) => {
        // console.log("handleArrowClick = (left)", left);
        if (left) {
            rightArrowRef.current?.scrollIntoView({behavior: 'smooth', block: 'start' });
        }
        else {
            leftArrowRef.current?.scrollIntoView({behavior: 'smooth', block: 'start' });
        }
    };

    const handleMouseEnter = () => {
        setShowArrow(isMobile && true);
    };

    const handleMouseLeave = () => {
        setShowArrow(isMobile && false);
    };

    return categoryList && categoryList.length > 0 ?
    <div className={`w-screen flex items-center justify-center mb-2 ${isMobile ? "" : "mt-12"}`} 
        onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} >
        <div className={`flex flex-row overflow-x-auto overflow-y-hidden`}>
            {showArrow && <div ref={leftArrowRef} onClick={() => handleArrowClick(true)} className="p-2">
                <div className={`justify text-center text-zinc-400 mt-1 cursor-pointer`}>
                    <span class="material-symbols-outlined">chevron_left</span>
                </div>
            </div>}
            <div className={`flex flex-row justify text-center`}>
                {categoryList && categoryList.map((item, key) => (
                    (item.sub_category_list && item.sub_category_list.length > 0) &&
                    <div key={key} onClick={() => setSelectedCategoryItem(item)} className="p-5">
                        <CText className="whitespace-nowrap cursor-pointer uppercase">
                            {categoryKey === item.category_key ? <b>{item.category_name}</b> : item.category_name}
                        </CText>
                    </div>
                ))}
            </div>
            {showArrow && <div ref={rightArrowRef} onClick={() => handleArrowClick(false)} className="p-2">
                <div className={`justify text-center text-zinc-400 mt-1 cursor-pointer`}>
                    <span class="material-symbols-outlined">chevron_right</span>
                </div>
            </div>}
        </div>
    </div> :
    <></>
}
