import ErrorAction from "./ErrorAction";

const initState = {
  apiError: false
};

export default class ErrorReducer {
  static initialState = initState;

  static reducer(state = ErrorReducer.initialState, action) {
    if (action.error) {
      return state;
    }
    switch (action.type) {
      case ErrorAction.API_ERROR:
        console.log("state.API_ERROR", state, action.payload);
        return { ...state, apiError: action.payload };
      default:
        return state;
    }
  }
}
