import HttpUtility from '../utils/http/HttpUtility';
import { ProductList, ProductCategoriesList } from "../utils/http/ApiUrls";


export default class ProductsEffect {
  static async productList(request) {
    // console.log ("ProductsEffect.productList - ", request);
    const page_no = request.pageNo || 1;
    const page_size = request.pageSize || 10;
    const order_by_type = request.orderBy || 'ASC';
    const include = request.include || "Y";
    const category_key = request.category_key ? ((request.category_key === '*') ? null : ('category_key=' + request.category_key)) : null;
    const sub_category_key = (request.sub_filter_items && request.sub_filter_items.length > 0) ? 'sub_category_key=' + request.sub_filter_items.join('~') : null;
    const product_key = request.product_key ? ('product_key=' + request.product_key) : null;

    let params = [];
    params.push ("page_no=" + ((page_no === null) ? 1 : page_no));
    params.push ("page_size=" + ((page_size === null) ? 10 : page_size));
    params.push ("order_by_type=" + ((order_by_type === null) ? "ASC" : order_by_type));
    params.push ("include=" + (include == null ? "Y" : include));
    
    if (category_key != null) params.push (category_key);
    if (sub_category_key != null) params.push (sub_category_key);
    if (product_key != null) params.push (product_key);
    
    // console.log("ProductsEffect.productList-------------->", params);
    return await HttpUtility.get(`${ProductList}?${params.join('&')}`);
  }

  static async productCategoryList(pageNo, pageSize, orderBy) {
    const page_no = pageNo || 1;
    const page_size = pageSize || 100;
    const order_by_type = orderBy || 'ASC';

    let params = [];
    params.push ("page_no=" + ((page_no === null) ? 1 : page_no));
    params.push ("page_size=" + ((page_size === null) ? 100 : page_size));
    params.push ("order_by_type=" + ((order_by_type === null) ? "ASC" : order_by_type));
    return await HttpUtility.get(`${ProductCategoriesList}?${params.join('&')}`);
  }
}
