import { useDispatch } from "react-redux";  //Requesting and Storing Data
import { useSelector } from "react-redux";  //Reading Data

import ProductsAction from "../../../../store/Products/ProductsAction";

export default function CategoryNavigation() {
    const dispatch = useDispatch();

    const productStore = useSelector((state) => state.products);
    // console.log("productsStore", productStore);
    const categoryList = productStore.productCategoryList.data || [];
    const selectedCategoryItem = productStore.selectedCategoryItem || {};
    let prevItem = null;
    let nextItem = null;

    if (categoryList.length > 0) {
        let prevInnerItem = null;
        for (let i=0; i< categoryList.length; i++) {
            const item = categoryList[i];
            const isMainMenu = item.sub_category_list && item.sub_category_list.length > 0;
            if (isMainMenu) {
                if (item.category_key === selectedCategoryItem.category_key) {
                    prevItem = prevInnerItem;
                    break;
                }
                prevInnerItem = item;
            }
        }

        let selectedItemFound = false;
        for (let i=0; i< categoryList.length; i++) {
            const item = categoryList[i];
            const isMainMenu = item.sub_category_list && item.sub_category_list.length > 0;
            if (isMainMenu) {
                if (selectedItemFound) {
                    nextItem = item;
                    break;
                }
                if (item.category_key === selectedCategoryItem.category_key) selectedItemFound = true;
            }
        }
    }

    const onPrevClick = () => {
        if (prevItem != null) dispatch(ProductsAction.setSelectedCategoryItem(prevItem));
    }

    const onNextClick = () => {
        if (nextItem != null) dispatch(ProductsAction.setSelectedCategoryItem(nextItem));
    }

    return (
        <>
         {prevItem != null && 
            <div style={{ padding: "20px", cursor: "pointer", position: 'absolute', top: '50%', zIndex: 5, left: 0 }} onClick={onPrevClick} >
                <span className="animate-bounce material-symbols-outlined text-white">arrow_back_ios</span>
            </div>
        }
        {nextItem != null && 
            <div style={{ padding: "20px", cursor: "pointer", position: 'absolute', top: '50%', zIndex: 5, right: 0 }} onClick={onNextClick}>
                <span className="animate-bounce material-symbols-outlined text-white">arrow_forward_ios</span>
        </div>
        }
       </>
    );
}