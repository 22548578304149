import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { HashRouter } from "react-router-dom";
import * as serviceWorker from './serviceWorker';

import { Provider } from "react-redux";
import configStore from "./store";

// Redux Store
const initialStore = {};
const store = configStore(initialStore);

ReactDOM.render(
    <HashRouter>
        <Provider store={store}>
            <App />
        </Provider>
    </HashRouter>, 
    document.getElementById("root")
);

serviceWorker.unregister();