import React, { useRef, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import queryString from 'query-string';

import ProductsEffect from "../../../../../store/Products/ProductsEffect";
import { isMobile } from "react-device-detect";
import CText from "../../../../../utilities/typography/ctext";
import SText from "../../../../../utilities/typography/stext";
import ProductLoader from "../../product-listing/product-loader";
import ProductGrid from "../../product-listing/product-grid/grid";

export default function YouMayLike(props) {
    console.log("YouMayLike", props);

    // Get Category List
    const productStore = useSelector((state) => state.products);
    // console.log("ProductListing -> productsStore", productStore);
    const categoryList = productStore.productCategoryList.data || [];
    
    const [isLoading, setIsLoading] = useState(false);
    const [paginationAttributes, setPaginationAttributes] = useState({ pageNo : 1, pageSize : 10, orderBy: 'ASC' });  
    const [productList, setProductList] = useState([]);  
    const title = props.title || "YOU MAY ALSO LIKE";
    const filter = props.filter;

    // Get Query String Items      
    let params = queryString.parse(window.location.hash.substring(window.location.hash.indexOf('?') + 1));
    // console.log("params", params, window.location.hash);
    const categoryKey = (!params.ckey || params.ckey === null) ? '*' : params.ckey;
    const subCategoryKey = (!params.sckey || params.sckey === null) ? '*' : params.sckey;
    
    useEffect(() => {    
          getAllProductCategories(categoryKey, [subCategoryKey], filter);
    }, []);


    const getAllProductCategories = async (category_key, sub_category_key_list, filter) => {
        setIsLoading(true);

        const sub_filter_items = [];
        if (filter && filter.categoriesSelected && filter.categoriesSelected.length > 0) {
            const selectedCategories = filter.categoriesSelected.map(c => c.sub_category_key);
            if (selectedCategories && selectedCategories.length > 0) sub_filter_items = selectedCategories; 
        }

        if (sub_filter_items.length === 0) {
            sub_category_key_list.map(c => {
                const cKey = c === '*' ? null : c;
                if (cKey !== null) sub_filter_items.push(cKey);
            });
        }
        
        const result = await ProductsEffect.productList({category_key, sub_category_key_list, include: "N", ...paginationAttributes});
        if (result.status === "success" && result.data && result.data.length > 0) {
          setProductList(result.data);
        }
        setIsLoading(false);
    }
    
    // Get Category List
    // const productStore = useSelector((state) => state.products);
    // console.log("YouMayLike -> productsStore", productStore);
    // const categoryList = productStore.productCategoryList.data || [];
    
    console.log("YouMayLike - productList", productList, categoryKey, subCategoryKey);

   
    return <div className="mt-32">
        <SText className="uppercase font-semibold border-b-4 ml-7 mt-36 w-fit">{title}</SText>
        {productList.length === 0 ? <ProductLoader /> :
        <>
            <ProductGrid fixItems={true} productList={productList} gridCol={isMobile ? 1 : 4}  />
            <div onClick={() => {}} className="mr-10 mb-10 text-right">
                <CText className="font-bold"><span className=" underline underline-offset-8 cursor-pointer" >Explore More</span> . . .</CText>
                </div>
        </>}
    </div>
}
