import React, { useRef, useState, useEffect } from "react";

import { isMobile } from "react-device-detect";
import ReadMore from "../../read-more";
import './style.css';
import CText from "../../../../../utilities/typography/ctext";
import SText from "../../../../../utilities/typography/stext";

export default function ProductLeftPanel(props) {
  const [showOverflow, setShowOverflow] = useState(false);

  // console.log("ProductLeftPanel", props);

  const product = props.product;
  return <div className="m-2 mt-18 ">
    {/* <SText className="uppercase"><b>{product.product_title}</b></SText> */}
    {(product.variantList && product.variantList.length > 1) &&
      <>
        <SText className="font-bold text-yellow-700 mt-4 mb-1 uppercase">similar options</SText>
        <div className=" border-2 p-2 border-zinc-100 rounded justify-between leading-normal">
          {product.variantList.map(variant => {
            return <div className="flex items-center justify-center cursor-pointer" onClick={() => props.onVariantChanged(variant)}>
              <img src={variant.thumbnail_sm_img_full_path} style={{ width: "75px" }} />
              <CText className="p-2">{variant.variant_title}</CText>
            </div>
          })}
        </div>
      </>
    }
    <pre className={`bg-white m-0 mt-6 ${showOverflow ? "overflow-y-auto" : "overflow-hidden"} pr-2`} style={{height: "500px"}} id="desc_scroll" 
      onMouseLeave={() => setShowOverflow(false)} onMouseEnter={() => setShowOverflow(true)}>
      <CText className=" text-justify whitespace-pre-wrap">
        <ReadMore charLimit={900} text={product.product_description} /></CText>
      {/* <CText className=" text-justify whitespace-pre-wrap">{product.product_description}</CText> */}
    </pre>
  </div>
}
