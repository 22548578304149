import React, { useState } from "react";

import { isMobile } from "react-device-detect";
import { Helmet } from "react-helmet-async";
import CText from "../../../../../utilities/typography/ctext";
import SText from "../../../../../utilities/typography/stext";

export default function Shipping() {

  return (
      <div>
            <Helmet>
              <title>Shipping Return - A Heritage from Hind</title>
              <meta
                name="description"
                content="Hintage Shipping - A Heritage from Hind"
              />
            </Helmet>
            <div className="container text-black p-6">
                <div className="row" style={{marginTop: isMobile ? "100px" : "175px"}}>
                    <div className="mb-12 text-2xl uppercase text-center ">Shipping Policy</div>
                    <div>
                        <div className="text-justify">
                            <CText className="pt-2 pb-6">
                                Hintage endeavors to ensure that your every purchase is seamless and memorable with the highest standard of quality and shopping experience.<br /><br />
                                We have a strict Quality Checking process embedded before each product is packed and dispatched. We take utmost care in packing to ensure zero damage during transit – domestic or international, and we ship through registered reputable shipping service providers. In rare scenarios, if any product gets damaged during transit, we take full accountability for the damage, allowing you to shop without any concerns about shipping damage.<br />
                            </CText>
                            <SText className="uppercase font-semibold">DOMESTIC SHIPPING</SText>
                            <SText className="uppercase">SHIPPING CHARGES</SText>
                            <CText className="pt-2 pb-6">
                                We offer Free Shipping within India on your minimum order value of Rs 2000 and above. Hence you will not pay any additional shipping charges at the time of checkout. In case your order value is less than Rs 2000, you will see the shipping charges at the time of checkout, in addition to the product prices.<br />
                                We use surface delivery and not air delivery. Each consignment is insured, hence in rare scenarios, if the consignment is lost or the product gets damaged during shipment, you will get a full refund or exchange.<br />
                            </CText>
                            <SText className="uppercase">DOMESTIC SHIPPING TIMELINE</SText>
                            <CText className="pt-2 pb-6">
                                We usually dispatch your order within 2-3 Business Days from the order placement date (the dispatch can get delayed in case of product non-readiness or any customization or any delay from the shipping company. However, this is a rare scenario).<br /><br />
                                Once dispatched from our end, the delivery timeline will depend on the shipping company. Usually, it takes 5 – 7 Business Days to deliver within India.<br /><br />
                                Please note: The delivery timelines above are indicative only. Since we rely on our shipping companies for delivery, this timeline can vary due to factors that are outside our control.
                            </CText>
                            <SText className="uppercase">INTERNATIONAL SHIPPING</SText>
                            <SText className="uppercase">SHIPPING CHARGES</SText>
                            <CText className="pt-2 pb-6">
                                Our international shipping is facilitated by reputable shipping companies. For International Shipping, the charges are calculated based on the volumetric weight, actual weight, and destination. Contact us for an approximate estimation of the shipping charge before you place the order. Call or WhatsApp us at [Phone Number] or write to us at care@hintage.in.<br />
                                Please note, our website does not recognize international shipping while you place an order with an international delivery address. Hence, it still shows Free Shipping on & above order value of Rs 2000. International shipping is NOT FREE.<br />
                                Once you pay the shipping charges to us, we will dispatch your order.<br />
                            </CText>
                            <SText className="uppercase">DUTIES & TAXES FOR INTERNATIONAL ORDERS</SText>
                            <CText className="pt-2 pb-6">
                                Duties & Taxes for international orders are not included in your order and are over and above any shipping charges paid to us. Most countries charge duties on imported items which are levied at the time of port entry, and they vary based on the destination country and the products imported. You will need to pay the applicable duties and taxes directly to the shipping companies at the time of your order delivery after you receive an invoice from the shipping agent.<br />
                            </CText>
                            <SText className="uppercase">INTERNATIONAL SHIPPING TIMELINE</SText>
                            <CText className="pt-2 pb-6">
                                We usually dispatch your order within 2-3 Business Days from the order placement date (the dispatch can get delayed in case of product non-readiness or any customization or any delay from the shipping company. However, this is a rare scenario).<br />
                                Once dispatched from our end, the delivery timeline will depend on the shipping company. Usually, it takes 5 – 7 Business Days to deliver anywhere in the world.<br />
                                Please note: The delivery timelines above are indicative only. Since we rely on reputable shipping companies for delivery, this timeline can vary due to factors that are outside our control.<br />
                            </CText>
                            <SText className="uppercase">NON-AVAILABILITY ON DELIVERY</SText>
                            <CText className="pt-2 pb-6">
                                Our shipping companies will attempt to deliver the order 3 times before they return it to us.<br />
                                Please provide the complete & accurate shipping address including zip code and a mobile number. This will help us in delivering your order faster.<br />
                            </CText>
                            <SText className="uppercase">CASH ON DELIVERY</SText>
                            <CText className="pt-2 pb-6">
                                We do not have Cash On Delivery option available.<br />
                            </CText>
                        </div>
                    </div>
                </div>
            </div>
        </div>
  );
}