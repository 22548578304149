import './App.css';
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GoogleOAuthProvider } from '@react-oauth/google';
import { SnackbarProvider } from "notistack";

// react-router components
import { Route, Switch, useLocation, useHistory } from "react-router-dom";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { NAVIGATION_MENU, FOOTER_ROUTES } from "./components/navigation";

import Home from "./components/page/zara/home";
import Page404 from "./components/page/page404";
import ProductsAction from './store/Products/ProductsAction';
import NetworkError from './components/page/zara/network-error';
import ErrorAction from './store/Error/ErrorAction';
import Wrapper from './components/page/zara/wrapper';


const REACT_APP_GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID || "";

export default function App() {
    const { pathname } = useLocation();
    const history = useHistory();
    const dispatch = useDispatch();
    
  
    const errorStore = useSelector((state) => state.error);
    console.log("errorStore", errorStore);
    const apiError = errorStore.apiError;
    
    const productStore = useSelector((state) => state.products);
    console.log("productsStore", productStore);
    const categoryList = productStore.productCategoryList.data;
    
    const [isLoading, setIsLoading] = useState(false);
    

    useEffect(() => {
        document.documentElement.scrollTop = 0;
        document.scrollingElement.scrollTop = 0;

        if (categoryList.length === 0) getAllProductCategories(null);
        
    }, [pathname]);
    
    const getAllProductCategories = async (category_key) => {
        setIsLoading(true);
        if (category_key === '*') category_key = null;
        const result = await dispatch(await ProductsAction.productCategoryList(1, 100, 'ASC'));
        console.log("result -------->", result);
        if (result.status === 0 && result.message === "Error requesting data") { //Network Error
            dispatch(ErrorAction.showApiError(true));
            // history.push('/network-error');
        }
        else if (result.status == "success") {
            const categoryList = result.data || [];
            if (categoryList.length > 0) {
                const matchCategory = categoryList.find((item) => item.sub_category_list && item.sub_category_list.length > 0);
                //   console.log("matchCategory", matchCategory);
                if (matchCategory) {
                    dispatch(ProductsAction.setSelectedCategoryItem(matchCategory));
                }
            }
        }
        setIsLoading(false);
  }


    const createRoute = (key, route) => (
        <Route exact key={key} render={() => <Wrapper route={route}>{route.component}</Wrapper>} path={route.href}
            //render={props => isAuthenticated ?  React.createElement(route.component, props) : history.push("/login") }
        />);

    console.log("pathname", pathname);

    const getRoutes = (routeMap) => {
        const routeList = [];
        routeMap.map((route) => {
            if (route.component)  routeList.push (route);
            if (route.children) {
              route.children.map((subRoute) => {
                if (subRoute.component)  routeList.push(subRoute);
                else if (subRoute.children) {
                  subRoute.children.map((subSubRoute) => {
                    if (subSubRoute.component) routeList.push(subSubRoute);
                  });
                }
              });
            }
          }
        );
        return routeList;
    }

    return (
                <GoogleOAuthProvider clientId={REACT_APP_GOOGLE_CLIENT_ID}>
                    <SnackbarProvider maxSnack={3}>
                        <HelmetProvider>
                            {apiError && <NetworkError />}
                            <Switch>
                                {getRoutes(NAVIGATION_MENU).map((route, key) => createRoute(key, route))}
                                {getRoutes(FOOTER_ROUTES).map((route, key) => createRoute(key, route))}
                                <Route exact path="/" component={Home} />
                                <Route component={Page404} />
                            </Switch>
                        </HelmetProvider>
                    </SnackbarProvider>
                </GoogleOAuthProvider>);
}