import React from "react";
import { Helmet } from "react-helmet-async";

const Page404 = () => (
  <div>
    <Helmet>
      <title>404 || Hintage - A Heritage from Hind</title>
    </Helmet>
    <div className="container relative pt-5 pb-16 lg:pb-20 lg:pt-5">
      <header className="text-center max-w-2xl mx-auto space-y-2">
        <span className="block text-sm text-neutral-800 sm:text-base dark:text-neutral-200 tracking-wider font-medium">
          THE PAGE YOU WERE LOOKING FOR DOESN'T EXIST.{" "}
        </span>
        <div className="pt-8">
          <button href="/" id="send" className="octf-btn">Return Home Page</button>
        </div>
      </header>
    </div>
  </div>
);

export default Page404;
