import CommonAction from "../Common/CommonAction";
import ActionUtility from "../../utilities/ActionUtility";
import ProductsEffect from "./ProductsEffect";
import Headers from "../utils/http/Headers";

export default class ProductsAction {
  static SAVE_CATEGORY_DATA = "ProductsAction.SAVE_CATEGORY_DATA";
  static SAVE_SELECTED_CATEGORY = "ProductsAction.SAVE_SELECTED_CATEGORY";

  static productCategoryList = (pageNo, pageSize, orderBy) => {
    return async (dispatch, getState) => {     
      let result = await ProductsEffect.productCategoryList(pageNo, pageSize, orderBy);
      if (result.status == "success" && result.data && result.data.length > 0) {
        localStorage.setItem(ProductsAction.SAVE_CATEGORY_DATA, JSON.stringify(result));
      }
      else {
        result = localStorage.getItem(ProductsAction.SAVE_CATEGORY_DATA) || { data: [] };
        if (result) result = JSON.parse(result);
      }

      //Add "ALL" category to list 
      let allItem = {category_key: "*", category_name: "All", active: "Y", sub_category_list: [], banner_images: []};
      
      const sub_category_list = [];
      let banner_images = [];

      const data = result.data || [];
      // if (data.length > 0 && data[0].category_key === '*') {
      //   data = data.splice(0,1);  //Remove AllItem * category
      // }
      data.map (item => {
        const isSubMenu = (!item.sub_category_list || item.sub_category_list == 0);
        if (!isSubMenu && item.banner_images && item.banner_images.length > 0) banner_images = [...banner_images, ...item.banner_images];

        if (!isSubMenu) {
          const mItem = JSON.parse(JSON.stringify(item));
          const subItem = mItem.sub_category_list || [];
          subItem.map(sItem => {
            // sItem.category_name = item.category_name + " > " + sItem.category_name;
            // console.log("1.... sItem", sItem);
            sub_category_list.push(sItem);
          });
        }
        // console.log ("banner_images --->", banner_images);
      });
      allItem.sub_category_list = sub_category_list;
      allItem.banner_images = banner_images;
      // console.log ("allItem", allItem);
      result.data = [allItem, ...data];
      
      dispatch(ActionUtility.createAction(ProductsAction.SAVE_CATEGORY_DATA, result));
      // console.log("---------> result", result);
      return result;
    };
  };

  static setSelectedCategoryItem = (item) => {
    return async (dispatch, getState) => {     
        dispatch(ActionUtility.createAction(ProductsAction.SAVE_SELECTED_CATEGORY, item));
    };
  };
  
}
