import React, { useState, useEffect, useRef } from "react";

import SwipeableBottomSheet from 'react-swipeable-bottom-sheet';
import Button from "../../form-components/button";
import YouMayLike from "../you-may-like/index,";
import Footer from "../../footer";
import { useSelector } from "react-redux";
import SText from "../../../../../utilities/typography/stext";

export default function ProductDescriptionBottomSheet(props) {
    const [open, setOpen] = useState(false);
    const [sameSubCategoryItems, setSameSubCategoryItems] = useState([]);  
    const [otherSubCategoryItems, setOtherSubCategoryItems] = useState([]);  

    const product = props.product || {};
    
    // Get Category ListfilterItems
    const productStore = useSelector((state) => state.products);
    console.log("ProductDescriptionBottomSheet -> productsStore", productStore);
    const categoryList = productStore.productCategoryList.data || [];

    useEffect(() => {
        setSubCategoryFilterList(product);
    }, [product]);

    
    function setSubCategoryFilterList (product) {

        /* Get all Sub-Categories in Same Main Category */ 
        const sameCategorySubItems = [];
        categoryList.map (c => {
            // console.log ("sameCategorySubItems", c, product);
            const matchProductCategoryFound = (c.category_key === product.category_key);
            if (matchProductCategoryFound)  {   //Main Category found
                if (c.sub_category_list && c.sub_category_list.length > 0) {
                    //get all Sub-categories inside main category other than product sub-category
                    const sameCatDiffSubCatList = c.sub_category_list.filter(s => s.sub_category_key !== product.sub_category_key);
                    if (sameCatDiffSubCatList && sameCatDiffSubCatList.length > 0) {
                        sameCatDiffSubCatList.map (item => {
                            const scItem = JSON.parse(JSON.stringify(sameCatDiffSubCatList[0]));
                            sameCategorySubItems.push (scItem);
                            return;
                        });
                    }
                }
            }
        });
        setSameSubCategoryItems(sameCategorySubItems);

        /* Get all Sub-Categories in Categories other than Product Categories */ 
        const otherCategoryItems = [];            
        categoryList.map (c => {
            // console.log ("otherCategoryItems", c, product);
            const parentCat = c.sub_category_list && c.sub_category_list.length > 0;
            if (!parentCat) {
                const matchProductCategoryFound = (c.category_key === product.category_key);
                if (!matchProductCategoryFound) {
                    const scItem = JSON.parse(JSON.stringify(c));
                    otherCategoryItems.push (scItem);
                }
            }
        });
        setOtherSubCategoryItems(otherCategoryItems);

        // console.log ("setSubCategoryFilterList", sameCategorySubItems, otherCategoryItems);
      }

    const openContainerStyle = open ? "overflow-scroll" : "";
    return <div className="absolute">
        <SwipeableBottomSheet 
                overflowHeight={150} 
                fullScreen={true} 
                marginTop={55} 
                open={open} 
                shadowTip={false} 
                topShadow={false} 
                overlay={false}
                scrollTopAtClose={true}
                onChange={(isOpen) => setOpen(isOpen)}
                style={{zIndex: 30 }}
            >
            <div>
                <div className="absolute h-4 w-full bg-zinc-100 -mt-4 grid place-items-center" 
                onClick={() => setOpen(!open)} 
                >
                    <div className="rounded-full h-1 w-16 bg-zinc-300" style={{left:"45%"}}></div>
                </div>
                
                <div className={`container p-2 mt-5 ${openContainerStyle}`}>
                    <div className="row">
                        <SText className="uppercase">{product.product_title}</SText>
                    </div>
                    <div className="row mt-5">
                        <Button>ADD TO CART</Button>
                        <a className={`material-symbols-outlined cursor-pointer text-black float-right m-2 mr-6`}>bookmark_add</a> 
                    </div>
                    <div className="row mt-10">
                        <YouMayLike filterSubCategoryItems={sameSubCategoryItems} title="SIMILAR ITEMS" />
                    </div>
                    <div className="row mt-10">
                        <YouMayLike filterSubCategoryItems={otherSubCategoryItems} />
                    </div>
                    <div className="row mt-10">
                        <Footer />
                    </div>
                </div>
            </div>
        </SwipeableBottomSheet>
        </div>
}