function Headers() {
  let sessionToken = '';
  let deviceId = '';

  return {
    getCommonHeaderParams() {
      return {
        'Content-Type': 'application/json',
        platform: 'web',
        deviceId: deviceId,
        Accept: 'application/json',
        // 'Access-Control-Allow-Headers': '*',
        // 'Access-Control-Allow-Methods': 'GET, PUT, POST, DELETE, OPTIONS',
        'access-control-allow-origin': '*',
      };
    },
    getCommonHeaderParamsEncryption() {
      return {
        'Content-Type': 'text/plain',
        platform: 'web',
        deviceId: deviceId,
        'access-control-allow-origin': '*',
      };
    },
    setSessionToken(token) {
      sessionToken = token;
    },
    setDeviceId(id) {
      deviceId = id;
    },
    getSessionToken() {
      return sessionToken;
    },
    getCommonHeadersWithToken() {
      return {
        ...this.getCommonHeaderParams(),
        Authorization: `Bearer ${sessionToken}`,
      };
    },
    getCommonHeadersWithTokenEncryption() {
      return {
        ...this.getCommonHeaderParamsEncryption(),
        Authorization: `Bearer ${sessionToken}`,
      };
    },
  };
}

export default Headers();